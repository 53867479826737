import { ChangeEvent, createRef, useEffect, useMemo, useState } from 'react';

import Modal from '../../../../components/common/Modal';
import { addCharge, editCharges } from '../../../../services/charges';
import useChargesContext from '../../Charges/hooks/useCharges.hook';
import { OptionsType, SelectRefProps } from '../../../../components/common/Select/types';
import { getBaseData } from '../../../../services/api';
import { RulesOptions } from '../../../../data/Rules';

function Form() {
  const { toastRef, modalRef, selectedRow, chargesList, setChargesList, resetGrid } = useChargesContext();
  const [chargesIncluded, setChargesIncluded] = useState('');
  const [state, setState] = useState('');
  const [type, setType] = useState('');
  const [taxforCharges, setTaxforCharges] = useState('');
  const [chargesState, setchargesState] = useState<any[]>([]);
  const stateref = createRef<SelectRefProps>();

  const fetchChargesList = async () => {
    const chargesResponse = await getBaseData();
    if (!('error' in chargesResponse)) {
      RulesOptions['state'] = chargesResponse['state'];
      setchargesState(RulesOptions.state);
    }
  };

  useEffect(() => {
    fetchChargesList();
  }, []);

  const stateOptions: OptionsType[] | undefined = useMemo(
    () =>
      chargesState?.reduce((options: OptionsType[], option) => {
        if (options.findIndex((value) => value.value === option) === -1) {
          options.push({ id: Number(option.id), value: option.value });
        }
        return options;
      }, []),
    [chargesState]
  );

  const handleFilterClick = (value: OptionsType) => {
    setState(value.value);
  };

  useEffect(() => {
    setChargesIncluded(selectedRow.chargesIncluded);
    setState(selectedRow.state);
    setType(selectedRow.type);
    setTaxforCharges(selectedRow.taxforCharges);
  }, [selectedRow]);

  const handleChargesIncluded = (e: ChangeEvent<HTMLInputElement>) => {
    setChargesIncluded(e.target.value);
  };
  const handleState = (e: ChangeEvent<HTMLInputElement>) => {
    setState(e.target.value);
  };
  const handleType = (e: ChangeEvent<HTMLInputElement>) => {
    setType(e.target.value);
  };
  const handleTaxforCharges = (e: ChangeEvent<HTMLInputElement>) => {
    setTaxforCharges(e.target.value);
  };

  const handleSave = () => {
    if (selectedRow.id) {
      handleUpdateCharges();
    } else {
      handleAddCharges();
    }
  };

  const handleUpdateCharges = async () => {
    const existingChargesData = chargesList.find((item) => item.id === selectedRow.id);

    if (!chargesIncluded) {
      toastRef.current?.showMessage('Please fill Charges Included', 'error');
      return;
    } else if (
      existingChargesData &&
      selectedRow.chargesIncluded === chargesIncluded &&
      selectedRow.state === state &&
      selectedRow.type === type &&
      selectedRow.taxforCharges === taxforCharges
    ) {
      closeModal();
      toastRef.current?.showMessage('No changes', 'success');
      return;
    }

    const editedResponse = await editCharges(Number(selectedRow.id), chargesIncluded, state, type, taxforCharges);

    if (!('error' in editedResponse)) {
      const updatedData = chargesList.map((value) =>
        value.id === selectedRow.id
          ? {
              ...value,
              chargesIncluded: editedResponse.chargesIncluded,
              state: editedResponse.state,
              type: editedResponse.type,
              taxforCharges: editedResponse.taxforCharges
            }
          : value
      );
      console.log(updatedData);
      setChargesList(updatedData);

      closeModal();
      resetGrid();
    }
  };

  const handleAddCharges = async () => {
    if (!chargesIncluded) {
      toastRef.current?.showMessage('Please fill Charges Included', 'error');
      return;
    } else if (!state) {
      toastRef.current?.showMessage('Please fill State', 'error');
      return;
    } else if (!type) {
      toastRef.current?.showMessage('Please fill Type', 'error');
      return;
    } else if (!taxforCharges) {
      toastRef.current?.showMessage('Please fill Tax for Charges', 'error');
      return;
    }

    const response = await addCharge(chargesIncluded, state, type, taxforCharges);

    if (!('error' in response)) {
      setChargesList([...chargesList, ...[response]]);

      closeModal();
      resetGrid();
    }
  };

  const closeModal = () => {
    modalRef.current?.hideModal();
    setChargesIncluded('');
    setState('');
    setType('');
    setTaxforCharges('');
    stateref.current?.reset();
  };

  return (
    <Modal.Container ref={modalRef} onClose={closeModal}>
      <Modal.Header heading=" Charges List" />
      <Modal.Form>
        <Modal.Input
          type="string"
          label="Charges Included"
          placeHolder={selectedRow.chargesIncluded}
          value={chargesIncluded}
          handleOnChange={(e: any) => handleChargesIncluded(e)}
        />
        <Modal.Select
          // type="string"
          // label="State"
          // placeHolder={state}
          // value={state}
          // handleOnChange={(e: any) => handleState(e)}
          name="State"
          label="State"
          options={stateOptions || []}
          placeholder={selectedRow.state ? selectedRow.state : 'select state'}
          defaultValue={''}
          type="single"
          disabled={!stateOptions}
          loading={!stateOptions}
          onOptionClick={(value) => handleFilterClick(value)}
          ref={stateref}
        />
        <Modal.Input
          type="string"
          label="Type"
          placeHolder={selectedRow.type}
          value={type}
          handleOnChange={(e: any) => handleType(e)}
        />
        <Modal.TextAreaInput
          type="string"
          label="Tax for Charges"
          placeHolder={selectedRow.taxforCharges}
          value={taxforCharges}
          handleOnChange={(e: any) => handleTaxforCharges(e)}
        />
      </Modal.Form>
      <Modal.Footer>
        <Modal.Button name="Save" onClick={handleSave} />
        <Modal.Button name="Close" onClick={() => closeModal()} />
      </Modal.Footer>
    </Modal.Container>
  );
}

export default Form;
