import {
  CommandModel,
  SelectionSettingsModel,
} from '@syncfusion/ej2-react-grids';

export const commandsEdit: CommandModel[] = [
  {
    type: 'Edit',
    buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' },
  },
];

export const editOptions = {
  allowEditing: false,
  allowAdding: false,
  allowDeleting: true,
  showDeleteConfirmDialog: true,
};

export const commandsDelete: CommandModel[] = [
  {
    type: 'Delete',
    buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' },
  },
];

export const settings: SelectionSettingsModel = {
  type: 'Multiple',
};
