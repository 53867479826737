import styles from './modal.module.css';

interface ButtonProps {
  name: string;
  onClick: () => void;
}

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button type="submit" className={`${styles.button_label} ${styles.buttoncolor}`} onClick={props.onClick}>
      {props.name}
    </button>
  );
};

export default Button;
