// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_charges_main__LAWSA{
    width: 96%;
    margin: auto;
}
.layout_head_center__IpCaJ{
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout_charges_gridhead__2eKna {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    position: relative;
    letter-spacing: 1px;
    font-family: 'SiemensSans_Prof_Bold';
    font-size: 22px;
    margin: 0.4em auto;
    color: #000;
    margin-top: 0.6em;
  }
  .layout_charges_gridhead__2eKna::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #333353;
    bottom: -10%;
    left: 0px;
    border-radius: 64px;
  }
  .layout_charges_gridhead__2eKna::after {
    content: '';
    position: absolute;
    width: 30%;
    height: 3px;
    background-color: #00af8e;
    bottom: -13%;
    left: 0px;
    border-radius: 64px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/AdminPanel/ServiceMappings/layout.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;AAChB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,oCAAoC;IACpC,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,SAAS;IACT,mBAAmB;EACrB;EACA;IACE,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,SAAS;IACT,mBAAmB;EACrB","sourcesContent":[".charges_main{\r\n    width: 96%;\r\n    margin: auto;\r\n}\r\n.head_center{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.charges_gridhead {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: -moz-fit-content;\r\n    width: fit-content;\r\n    text-align: center;\r\n    position: relative;\r\n    letter-spacing: 1px;\r\n    font-family: 'SiemensSans_Prof_Bold';\r\n    font-size: 22px;\r\n    margin: 0.4em auto;\r\n    color: #000;\r\n    margin-top: 0.6em;\r\n  }\r\n  .charges_gridhead::before {\r\n    content: '';\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 1px;\r\n    background-color: #333353;\r\n    bottom: -10%;\r\n    left: 0px;\r\n    border-radius: 64px;\r\n  }\r\n  .charges_gridhead::after {\r\n    content: '';\r\n    position: absolute;\r\n    width: 30%;\r\n    height: 3px;\r\n    background-color: #00af8e;\r\n    bottom: -13%;\r\n    left: 0px;\r\n    border-radius: 64px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"charges_main": `layout_charges_main__LAWSA`,
	"head_center": `layout_head_center__IpCaJ`,
	"charges_gridhead": `layout_charges_gridhead__2eKna`
};
export default ___CSS_LOADER_EXPORT___;
