import ErrorPage from '../../../components/common/Error';
import { UserRoles } from '../../../constants/userRoles';
import useAuth from '../../../hooks/useAuth';
import styles from './main.module.css';

import { NavLink } from 'react-router-dom';

function Main() {
  const { auth } = useAuth();
  const navLinkStyle = { textDecoration: 'none' };

  return (
    <div className={styles.admin_container}>
      {auth.accessType === UserRoles.User && <ErrorPage />}
      {auth.accessType === UserRoles.Admin && (
        <>
          <br />
          <br />
          <h4 className={styles.head_text}>Admin Panel</h4>
          <br />
          <br />
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <NavLink to="/charges" style={navLinkStyle}>
                  <div className={styles.main_links}>
                    <div className={styles.sidebar_item}>Charges</div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/ServiceCertificate" style={navLinkStyle}>
                  <div className={styles.main_links}>
                    <div className={styles.sidebar_item}>Service Agreement Acceptable Certificates</div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/ProjectCertificate" style={navLinkStyle}>
                  <div className={styles.main_links}>
                    <div className={styles.sidebar_item}>Project Acceptable Certificates</div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/ServiceMapping" style={navLinkStyle}>
                  <div className={styles.main_links}>
                    <div className={styles.sidebar_item}>Service Agreement Mapping</div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/ProjectMapping" style={navLinkStyle}>
                  <div className={styles.main_links}>
                    <div className={styles.sidebar_item}>Projects Mapping</div>
                  </div>
                </NavLink>
              </div>
              <div className="col-md-4">
                <NavLink to="/QuotedMapping" style={navLinkStyle}>
                  <div className={styles.main_links}>
                    <div className={styles.sidebar_item}>Quoted Service Mapping</div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Main;
