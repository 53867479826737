import Form from '../ServiceAgreementCertificate/ModalForm';

import { ServiceCertificateProvider } from './hooks/context';
import ServiceAgreementGrid from './ServiceAgreementGrid';

import styles from './index.module.css';
function Layout() {
  return (
    <ServiceCertificateProvider>
      <div className="container-fluid">
        <div className={styles.charges_main}>
          <div className={styles.head_center}>
            <h4 className={styles.charges_gridhead}>Service Agreement Acceptable Certificate List</h4>
          </div>

          <Form />
          <div>
            <ServiceAgreementGrid />
          </div>
        </div>
      </div>
    </ServiceCertificateProvider>
  );
}

export default Layout;
