import Form from '../ProjectMappings/ModalForm';

import styles from './layout.module.css';
import { ProjectMappingProvider } from './hooks/context';
import ProjectMappingGrid from './ProjectMappingGrid';

function Layout() {
  return (
    <ProjectMappingProvider>
      <div className="container-fluid">
        <div className={styles.charges_main}>
          <div className={styles.head_center}>
            <h4 className={styles.charges_gridhead}>Projects Mapping List</h4>
          </div>
          <Form />
          <div>
            <ProjectMappingGrid />
          </div>
        </div>
      </div>
    </ProjectMappingProvider>
  );
}

export default Layout;
