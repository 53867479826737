import { ChangeEvent } from 'react';

import styles from './input.module.css';

interface InputPropsType {
  label: string;
  type: string;
  placeHolder: string;
  value: string;
  handleOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function Input(props: Readonly<InputPropsType>) {
  const { type, placeHolder, label, value, handleOnChange } = props;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleOnChange(e);
  };

  return (
    <>
      <label className={`control-label ${styles.input_labeldesign}`}>{label}</label>
      <input
        type={type}
        className={`form-control ${styles.input_box}`}
        placeholder={placeHolder}
        value={value}
        onChange={handleInputChange}
      />
    </>
  );
}

export default Input;
