import styles from './errorpage.module.css';

function ErrorPage() {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <h6>403</h6>
        <small>Oops!</small>
        <p>Sorry, You don't have permission to view this page.</p>
      </div>
    </div>
  );
}

export default ErrorPage;
