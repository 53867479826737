import { MsalProvider } from '@azure/msal-react';
import { registerLicense } from '@syncfusion/ej2-base';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import '../src/styles/index.css';
import { msalSetup } from './auth/azureAD/setup';
import { RecoilRoot } from 'recoil';
import { loginApiRequest } from './auth/azureAD/authConfig';
import { AuthProvider } from './context/AuthContext';
// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9SdEdjXn9fdXBXT2ZV');

const msalInstance = msalSetup();

axios.interceptors.request.use(
  async (response) => {
    const account = msalInstance.getAllAccounts()[0];

    const msalResponse = await msalInstance.acquireTokenSilent({
      ...loginApiRequest,
      account: account
    });

    //response.headers['XApiKey'] = 'pgH7QzFsadavSUDV2385/.;L,.IOY87G83GE28Y.[./;]O[GSE38ERvtTwlEXp';
    response.headers['Authorization'] = `Bearer ${msalResponse.accessToken}`;

    return response;
  },
  (err) => {
    console.error('MSAL Error', err);
    throw err;
  }
);

// export const msalInstance = new PublicClientApplication(msalConfig);
// const init = msalInstance.initialize();

// axios.defaults.baseURL = 'https://taxrulesus.azurewebsites.net/api/';
// axios.interceptors.request.use(
//   async (response) => {
//     const account = msalInstance.getAllAccounts()[0];
//     const msalResponse = await msalInstance.acquireTokenSilent({
//       ...loginApiRequest,
//       account: account
//     });
//     response.headers['Authorization'] = `Bearer ${msalResponse.accessToken}`;
//     return response;
//   },
//   (err) => {
//     console.error('MSAL Error', err);
//     return Promise.reject(err);
//   }
// );

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <MsalProvider instance={msalInstance}>
  //   <BrowserRouter>
  //     <App />
  //   </BrowserRouter>
  // </MsalProvider>
  <MsalProvider instance={msalInstance}>
    <RecoilRoot>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </RecoilRoot>
  </MsalProvider>
);
