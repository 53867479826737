import { LogLevel, ProtocolMode } from '@azure/msal-browser';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; /* Only needed if you need to support the
                                  redirect flow in Firefox incognito*/

export const msalConfig = {
  auth: {
    clientId: '5a7b460f-a5a1-490b-a005-5d0b76d09519',
    authority: 'https://login.microsoftonline.com/38ae3bcd-9579-4fd4-adda-b42e1495d55a',
    knownAuthorities: [],
    cloudDiscoveryMetadata: '',
    redirectUri: '/login',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
    clientCapabilities: ['CP1'],
    protocolMode: 'AAD' as ProtocolMode,
  },
  cache: {
    cacheLocation: 'localStorage',
    temporaryCacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
    secureCookies: false,
    claimsBasedCachingEnabled: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
  telemetry: {
    application: {
      appName: 'Construction wiz',
      appVersion: '1.0.0',
    },
  },
};

export const loginApiRequest = {
  scopes: ['api://c139960c-a23c-44ff-89e3-501e549a4e07/api.scope']
};