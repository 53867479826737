import { useContext } from 'react';

import { ServiceMappingContext } from './context';

export default function useServiceMappingContext() {
  const context = useContext(ServiceMappingContext);

  if (!context) {
    throw new Error('useServiceMappingContext should be inside ServiceMappingContext');
  }

  return context;
}
