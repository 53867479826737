// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serviceMappingList_state_main__FXYHA{
    margin: 8px auto;
}
.serviceMappingList_button_label__occ-5 {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 10px 0px;
    height: 44px;
    text-align: center;
    border: none;
    border-radius: 0.2em !important;
    letter-spacing: 0.5px;
    position: relative;
  }
  
  .serviceMappingList_button_label__occ-5:hover {
    background: linear-gradient(
        90deg,
        rgba(0, 100, 110, 1) 0%,
        rgba(0 ,175, 142, 1) 100%
      );
  }
  
  .serviceMappingList_button_label__occ-5.serviceMappingList_buttoncolor__Ngkbo:focus {
    outline: none;
    background: linear-gradient(
        90deg,
        rgba(0, 100, 110, 1) 0%,
        rgba(0 ,175, 142, 1) 100%
      );
    transform: scale(1);
    color: #fff;
  }
  
  .serviceMappingList_button_label__occ-5.serviceMappingList_buttoncolor__Ngkbo {
    background: linear-gradient(
        90deg,
        rgba(0, 100, 110, 1) 0%,
        rgba(0 ,175, 142, 1) 100%
      );
    box-shadow: 0 1px 10px 0 rgba(0, 0, 40, 0.65);
  }`, "",{"version":3,"sources":["webpack://./src/pages/AdminPanel/ServiceMappings/ServiceMappingGrid/serviceMappingList.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,+BAA+B;IAC/B,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE;;;;OAIG;EACL;;EAEA;IACE,aAAa;IACb;;;;OAIG;IACH,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE;;;;OAIG;IACH,6CAA6C;EAC/C","sourcesContent":[".state_main{\r\n    margin: 8px auto;\r\n}\r\n.button_label {\r\n    width: 100%;\r\n    font-size: 14px;\r\n    font-weight: 600;\r\n    color: #fff;\r\n    cursor: pointer;\r\n    margin: 10px 0px;\r\n    height: 44px;\r\n    text-align: center;\r\n    border: none;\r\n    border-radius: 0.2em !important;\r\n    letter-spacing: 0.5px;\r\n    position: relative;\r\n  }\r\n  \r\n  .button_label:hover {\r\n    background: linear-gradient(\r\n        90deg,\r\n        rgba(0, 100, 110, 1) 0%,\r\n        rgba(0 ,175, 142, 1) 100%\r\n      );\r\n  }\r\n  \r\n  .button_label.buttoncolor:focus {\r\n    outline: none;\r\n    background: linear-gradient(\r\n        90deg,\r\n        rgba(0, 100, 110, 1) 0%,\r\n        rgba(0 ,175, 142, 1) 100%\r\n      );\r\n    transform: scale(1);\r\n    color: #fff;\r\n  }\r\n  \r\n  .button_label.buttoncolor {\r\n    background: linear-gradient(\r\n        90deg,\r\n        rgba(0, 100, 110, 1) 0%,\r\n        rgba(0 ,175, 142, 1) 100%\r\n      );\r\n    box-shadow: 0 1px 10px 0 rgba(0, 0, 40, 0.65);\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"state_main": `serviceMappingList_state_main__FXYHA`,
	"button_label": `serviceMappingList_button_label__occ-5`,
	"buttoncolor": `serviceMappingList_buttoncolor__Ngkbo`
};
export default ___CSS_LOADER_EXPORT___;
