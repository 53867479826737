import {
  ColumnDirective,
  ColumnsDirective,
  CommandClickEventArgs,
  CommandColumn,
  GridComponent,
  Inject,
  Resize
} from '@syncfusion/ej2-react-grids';
import { createRef, useMemo } from 'react';

import AlertModal, { AlertModalRefProps } from '../../../../components/common/AlertModal';
import Select, { OptionsType } from '../../../../components/common/Select';
import Toast from '../../../../components/common/Toast';
import { deleteQuotedServiceMapping } from '../../../../services/QuotedServices';
import { MappingsType } from '../../../../types/Mappings';
import { changeGridType } from '../../../../utils/grid';
import { commandsDelete, commandsEdit, editOptions, settings } from '../../config';
import useQuotedServiceMappingContext from '../hooks/useQuotedServiceMapping.hook';
import BackButton from '../../../../components/common/BackButton/BackButton';

import styles from './quotedServiceMappingList.module.css';
function QuotedServiceMappingList() {
  const {
    toastRef,
    modalRef,
    quotedServiceMappingList,
    setQuotedServiceMappingList,
    selectedRow,
    setSelectedRow,
    mappingFullArray,
    gridRefreshRef,
    resetGrid
  } = useQuotedServiceMappingContext();

  const alertModalRef = createRef<AlertModalRefProps>();

  const stateOptions: OptionsType[] | undefined = useMemo(
    () =>
      mappingFullArray?.reduce((options: OptionsType[], option) => {
        if (options.findIndex((value) => value.value === option.state) === -1) {
          options.push({ id: Number(option.id), value: option.state });
        }
        return options;
      }, []),
    [mappingFullArray]
  );

  const handleFilterClick = (value: OptionsType) => {
    const selectedValue = value.value;
    const filteredArray = mappingFullArray.filter(function (data) {
      return data.state === selectedValue;
    });
    setQuotedServiceMappingList(filteredArray);
    resetGrid();
  };

  const clickEditorDeleteMapping = (args: CommandClickEventArgs) => {
    if (args.commandColumn && args.rowData) {
      setSelectedRow(args.rowData as MappingsType);

      if (args.commandColumn.type === 'Edit') {
        modalRef.current?.showModal();
      } else if (args.commandColumn.type === 'Delete') {
        alertModalRef.current?.showConfirmBox();
      }
    }
  };

  // *********************************Delete serviceCertificateData
  const onClickYes = async () => {
    alertModalRef.current?.hideConfirmBox();

    const removeResponse = await deleteQuotedServiceMapping(Number(selectedRow.id));
    if (removeResponse) {
      const filteredData = quotedServiceMappingList.filter((value) => value.id !== selectedRow.id);
      setQuotedServiceMappingList(filteredData);
      setSelectedRow({} as MappingsType);
    } else {
      setQuotedServiceMappingList(quotedServiceMappingList);
    }
    resetGrid();
  };

  const onClickNo = () => {
    alertModalRef.current?.hideConfirmBox();

    setSelectedRow({} as MappingsType);
  };
  // *********************************Delete serviceCertificateData

  return (
    <>
      <Toast ref={toastRef} />
      <AlertModal.Container ref={alertModalRef}>
        <AlertModal.Icon iconClassName="" />
        <AlertModal.Content content="Want to delete?" />
        <AlertModal.Buttons>
          <AlertModal.Button name="Yes" filled onClick={onClickYes} />
          <AlertModal.Button name="No" onClick={onClickNo} />
        </AlertModal.Buttons>
      </AlertModal.Container>

      <div className={styles.state_main}>
        <div className="row">
          <div className="col-md-2">
            <div className={styles.state_main}>
              <Select
                name="State"
                label="State"
                options={stateOptions || []}
                placeholder={'Select State'}
                defaultValue={''}
                type="single"
                disabled={!stateOptions}
                loading={!stateOptions}
                onOptionClick={(value) => handleFilterClick(value)}
              />
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-2">
            <BackButton />
          </div>
          <div className="col-md-2">
            <div className={'styles.add_maindepth'}>
              <button
                className={`${styles.button_label} ${styles.buttoncolor}`}
                onClick={() => {
                  setSelectedRow({} as MappingsType);
                  modalRef.current?.showModal();
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>

        <div key={gridRefreshRef} className="gjhg" id="grid">
          <GridComponent
            dataSource={changeGridType(quotedServiceMappingList)}
            selectionSettings={settings}
            editSettings={editOptions}
            commandClick={clickEditorDeleteMapping}
            allowResizing={true}
            width="100%"
            gridLines="Both"
          >
            <ColumnsDirective>
              <ColumnDirective field="projectType" headerText="Project Type" width="200" textAlign="Center" />
              <ColumnDirective field="gckCode" headerText="GCK Code" width="200" textAlign="Center" />
              <ColumnDirective
                field="businessHierarchy"
                headerText="Business Hierarchy"
                width="200"
                textAlign="Center"
              />
              <ColumnDirective field="materialNumber" headerText="Material Number" width="200" textAlign="Center" />
              <ColumnDirective
                field="materialDescription"
                headerText="Material Description"
                width="300"
                textAlign="Center"
              />
              <ColumnDirective field="productCode" headerText="Product Code" width="150" textAlign="Center" />
              <ColumnDirective field="state" headerText="State" width="180" textAlign="Center" />
              <ColumnDirective
                field="taxabilityGuidance"
                headerText="Taxability Guidance"
                width="300"
                textAlign="Center"
              />
              <ColumnDirective field="materialTaxation" headerText="material Taxation" width="200" textAlign="Center" />
              <ColumnDirective
                headerText="Edit"
                width="80"
                commands={commandsEdit}
                textAlign="Center"
                background-color="#000"
              />
              <ColumnDirective headerText="Delete" width="80" commands={commandsDelete} textAlign="Center" />
            </ColumnsDirective>
            <Inject services={[CommandColumn, Resize]} />
          </GridComponent>
        </div>
      </div>
    </>
  );
}

export default QuotedServiceMappingList;
