import Buttons, { Button } from './Buttons';
import Container, { AlertModalRefProps, ContainerProps } from './Container';
import Content from './Content';
import Icon from './Icon';

type AlertModalType = {
  Container: React.ForwardRefExoticComponent<ContainerProps & React.RefAttributes<AlertModalRefProps>>;
  Icon: typeof Icon;
  Content: typeof Content;
  Buttons: typeof Buttons;
  Button: typeof Button;
};

const AlertModal: AlertModalType = {
  Container: Container,
  Icon: Icon,
  Content: Content,
  Buttons: Buttons,
  Button: Button
};

export default AlertModal;
export { Button, Buttons, Container, Content, Icon };
