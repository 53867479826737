import axios from 'axios';
import { ErrorResponseType } from '../types/ResponseType';

import { API_URL, BEARER_TOKEN } from '../constants/api';
import { AcceptableCertificateType } from '../types/AccetableCertificate';

// * -------------- Headers declaration for Request API Begin---------------------------
interface HeaderType {
  accessControlCredential: string;
  accessControlOrigin: string;
  apiKey: string;
}

const HeaderFields: HeaderType = {
  accessControlCredential: 'Access-Control-Allow-Credentials',
  accessControlOrigin: 'Access-Control-Allow-Origin',
  apiKey: 'XApiKey'
};

const HeaderValues: HeaderType = {
  accessControlCredential: '*',
  accessControlOrigin: '*',
  apiKey: BEARER_TOKEN
};

const requestHeaders = {
  [HeaderFields.accessControlCredential]: HeaderValues.accessControlCredential,
  [HeaderFields.accessControlOrigin]: HeaderValues.accessControlOrigin,
  [HeaderFields.apiKey]: HeaderValues.apiKey
};

export async function getProjectCertificate(): Promise<AcceptableCertificateType[] | ErrorResponseType> {
  try {
    const { data, status } = await axios.get(API_URL + '/Project/ProjectDump', {
      headers: requestHeaders
    });

    if (status === 200) {
      console.info('ProjectCertificate read response success');

      return data;
    } else {
      console.error('Unexpected error status code:', status);
      return {
        error: 'An unexpected error occurred in ProjectCertificate read response',
        statusCode: status
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return {
        error: 'An unexpected error occurred in ProjectCertificate read response'
      };
    }
  }
}

export async function addProjectCertificate(
  typeofentity: string,
  state: string,
  acceptableCertificates: string
): Promise<AcceptableCertificateType | ErrorResponseType> {
  try {
    const { data, status } = await axios.post(
      API_URL + `/Project/AddProject`,
      {
        typeofentity: typeofentity,
        state: state,
        acceptableCertificates: acceptableCertificates
      },
      { headers: requestHeaders }
    );

    if (status === 200) {
      console.info('ProjectCertificate add response success');
      return data.data;
    } else {
      console.error('Unexpected error status code:', status);
      return { error: 'An unexpected error occurred in ProjectCertificate add response', statusCode: status };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred in ProjectCertificate add response' };
    }
  }
}

export async function editProjectCertificate(
  id: number,
  typeofentity: string,
  state: string,
  acceptableCertificates: string
): Promise<AcceptableCertificateType | ErrorResponseType> {
  try {
    const { data, status } = await axios.put(
      API_URL + `/Project/EditProjectRecord/${id}`,
      {
        id: id,
        typeofentity: typeofentity,
        state: state,
        acceptableCertificates: acceptableCertificates
      },
      { headers: requestHeaders }
    );

    if (status === 200) {
      console.info('ProjectCertificate edit response success');

      return data.data;
    } else {
      console.error('Unexpected error status code:', status);
      return { error: 'An unexpected error occurred in ProjectCertificate edit response', statusCode: status };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred in ProjectCertificate edit response' };
    }
  }
}

export async function deleteProjectCertificate(id: number): Promise<boolean> {
  try {
    const { status } = await axios.delete(API_URL + `/Project/DeleteProject/${id}`, {
      headers: requestHeaders
    });

    if (status === 200) {
      console.info('ProjectCertificate delete response success');
      return true;
    } else {
      console.error('Unexpected error status code:', status);
      return false;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}
