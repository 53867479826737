import Button from '../Modal/Button';
import Container, { ContainerProps, ContainerRefProps } from '../Modal/Container';
import Footer from '../Modal/Footer';
import Form from '../Modal/Form';
import Header from '../Modal/Header';
import Input from '../Modal/Input';
import Select from './Select';
import TextAreaInput from './Textarea';

type ModalType = {
  Container: React.ForwardRefExoticComponent<ContainerProps & React.RefAttributes<ContainerRefProps>>;
  Header: typeof Header;
  Form: typeof Form;
  Footer: typeof Footer;
  Input: typeof Input;
  Button: typeof Button;
  TextAreaInput: typeof TextAreaInput;
  Select: typeof Select;
};

const Modal: ModalType = {
  Container: Container,
  Header: Header,
  Form: Form,
  Footer: Footer,
  Input: Input,
  Button: Button,
  TextAreaInput: TextAreaInput,
  Select: Select
};

export default Modal;
export { Button, Container, Footer, Form, Header, Input, TextAreaInput, Select };
