import styles from './alertmodal.module.css';

interface ButtonsProps {
  children: React.ReactNode;
}

interface ButtonProps {
  name: string;
  filled?: boolean;
  onClick: () => void;
}

const Buttons: React.FC<ButtonsProps> = (props) => <div className={styles['buttons-container']}>{props.children}</div>;

export const Button: React.FC<ButtonProps> = (props) => {
  const styleName = props.filled ? 'ok-button' : 'cancel-button';
  return (
    <button className={styles[styleName]} onClick={props.onClick}>
      {props.name}
    </button>
  );
};

export default Buttons;
