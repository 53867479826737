import { ChangeEvent } from 'react';

import InputField from '../Input';

interface InputProps {
  label: string;
  type: string;
  placeHolder: string;
  value: string;
  handleOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({ label, type, placeHolder, value, handleOnChange }) => (
  <>
    <InputField
      label={label}
      value={value}
      type={type}
      placeHolder={placeHolder}
      handleOnChange={(e) => handleOnChange(e)}
    />
  </>
);

export default Input;
