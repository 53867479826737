import { useContext } from 'react';

import { ProjectCertificateContext } from './context';

export default function useProjectCertificateContext() {
  const context = useContext(ProjectCertificateContext);

  if (!context) {
    throw new Error('useProjectCertificateContext should be inside ProjectCertificateContext');
  }

  return context;
}
