import { createContext, createRef, Dispatch, ReactNode, RefObject, useEffect, useMemo, useState } from 'react';
import { SetterOrUpdater } from 'recoil';

import { ModalRefProps } from '../../../../components/common/Modal';
import { ToastRefProps } from '../../../../components/common/Toast';
import { getServiceMapping } from '../../../../services/ServiceMappings';
import { MappingsType } from '../../../../types/Mappings';

type ServiceMappingProviderProps = {
  children: ReactNode;
};

type ServiceMappingList = {
  toastRef: RefObject<ToastRefProps>;
  modalRef: RefObject<ModalRefProps>;
  selectedRow: MappingsType;
  setSelectedRow: Dispatch<MappingsType>;
  serviceMappingList: MappingsType[];
  setServiceMappingList: SetterOrUpdater<MappingsType[]>;
  mappingFullArray: MappingsType[];
  setMappingFullArray: SetterOrUpdater<MappingsType[]>;
  gridRefreshRef: number;
  resetGrid: () => Promise<void>;
};

export const ServiceMappingContext = createContext<ServiceMappingList | null>(null);

export function ServiceMappingProvider(props: Readonly<ServiceMappingProviderProps>) {
  const toastRef = createRef<ToastRefProps>();
  const modalRef = createRef<ModalRefProps>();

  const [selectedRow, setSelectedRow] = useState<MappingsType>({} as MappingsType);

  const [serviceMappingList, setServiceMappingList] = useState<MappingsType[]>([] as MappingsType[]);
  const [mappingFullArray, setMappingFullArray] = useState<MappingsType[]>([] as MappingsType[]);

  const [gridRefreshRef, setGridRefreshRef] = useState(1);
  const resetGrid = async () => {
    //* Refresh grid every operation done
    setGridRefreshRef(Math.random());
  };

  const fetchServiceMappingList = async () => {
    const serviceResponse = await getServiceMapping();

    if (!('error' in serviceResponse)) {
      setServiceMappingList(serviceResponse);
      setMappingFullArray(serviceResponse);
    }
  };

  useEffect(() => {
    fetchServiceMappingList();
  }, []);

  const selectStates = useMemo(
    () => ({
      selectedRow,
      setSelectedRow
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRow]
  );

  return (
    <ServiceMappingContext.Provider
      value={{
        ...{ toastRef, modalRef, gridRefreshRef, resetGrid },
        ...selectStates,
        serviceMappingList,
        setServiceMappingList,
        mappingFullArray,
        setMappingFullArray
      }}
    >
      {props.children}
    </ServiceMappingContext.Provider>
  );
}
