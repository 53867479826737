import { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';

import UserInfoType from '../types/User';

type UserContextType = {
  auth: UserInfoType;
  setAuth: Dispatch<SetStateAction<UserInfoType>>;
};

type AuthProviderType = {
  children: ReactNode;
};

const AuthContext = createContext<UserContextType>({} as UserContextType);

export const AuthProvider: FC<AuthProviderType> = ({ children }) => {
  const [auth, setAuth] = useState<UserInfoType>({} as UserInfoType);
  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
