import { createContext, createRef, Dispatch, ReactNode, RefObject, useEffect, useMemo, useState } from 'react';
import { SetterOrUpdater } from 'recoil';

import { ModalRefProps } from '../../../../components/common/Modal';
import { ToastRefProps } from '../../../../components/common/Toast';
import { getServiceCertificate } from '../../../../services/ServiceCertificate';
import { AcceptableCertificateType } from '../../../../types/AccetableCertificate';

type ServiceCertificateProviderProps = {
  children: ReactNode;
};

type ServiceCertificateList = {
  toastRef: RefObject<ToastRefProps>;
  modalRef: RefObject<ModalRefProps>;
  selectedRow: AcceptableCertificateType;
  setSelectedRow: Dispatch<AcceptableCertificateType>;
  serviceCertificateList: AcceptableCertificateType[];
  setServiceCertificateList: SetterOrUpdater<AcceptableCertificateType[]>;
  certificateFullArray: AcceptableCertificateType[];
  setCertificateFullArray: SetterOrUpdater<AcceptableCertificateType[]>;
  gridRefreshRef: number;
  resetGrid: () => Promise<void>;
};

export const ServiceCertificateContext = createContext<ServiceCertificateList | null>(null);

export function ServiceCertificateProvider(props: Readonly<ServiceCertificateProviderProps>) {
  const toastRef = createRef<ToastRefProps>();
  const modalRef = createRef<ModalRefProps>();

  const [selectedRow, setSelectedRow] = useState<AcceptableCertificateType>({} as AcceptableCertificateType);

  const [serviceCertificateList, setServiceCertificateList] = useState<AcceptableCertificateType[]>(
    [] as AcceptableCertificateType[]
  );
  const [certificateFullArray, setCertificateFullArray] = useState<AcceptableCertificateType[]>(
    [] as AcceptableCertificateType[]
  );

  const [gridRefreshRef, setGridRefreshRef] = useState(1);
  const resetGrid = async () => {
    //* Refresh grid every operation done
    setGridRefreshRef(Math.random());
  };

  const fetchServiceCertificateList = async () => {
    const serviceResponse = await getServiceCertificate();

    if (!('error' in serviceResponse)) {
      setServiceCertificateList(serviceResponse);
      setCertificateFullArray(serviceResponse);
    }
  };

  useEffect(() => {
    fetchServiceCertificateList();
  }, []);

  const selectStates = useMemo(
    () => ({
      selectedRow,
      setSelectedRow
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRow]
  );

  return (
    <ServiceCertificateContext.Provider
      value={{
        ...{ toastRef, modalRef, gridRefreshRef, resetGrid },
        ...selectStates,
        serviceCertificateList,
        setServiceCertificateList,
        certificateFullArray,
        setCertificateFullArray
      }}
    >
      {props.children}
    </ServiceCertificateContext.Provider>
  );
}
