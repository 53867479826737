import axios from 'axios';
import { ErrorResponseType } from '../types/ResponseType';

import { API_URL, BEARER_TOKEN } from '../constants/api';
import { AcceptableCertificateType } from '../types/AccetableCertificate';

// * -------------- Headers declaration for Request API Begin---------------------------
interface HeaderType {
  accessControlCredential: string;
  accessControlOrigin: string;
  apiKey: string;
}

const HeaderFields: HeaderType = {
  accessControlCredential: 'Access-Control-Allow-Credentials',
  accessControlOrigin: 'Access-Control-Allow-Origin',
  apiKey: 'XApiKey'
};

const HeaderValues: HeaderType = {
  accessControlCredential: '*',
  accessControlOrigin: '*',
  apiKey: BEARER_TOKEN
};

const requestHeaders = {
  [HeaderFields.accessControlCredential]: HeaderValues.accessControlCredential,
  [HeaderFields.accessControlOrigin]: HeaderValues.accessControlOrigin,
  [HeaderFields.apiKey]: HeaderValues.apiKey
};

export async function getServiceCertificate(): Promise<AcceptableCertificateType[] | ErrorResponseType> {
  try {
    const { data, status } = await axios.get(API_URL + '/ServiceAgreement/SADump', {
      headers: requestHeaders
    });

    if (status === 200) {
      console.info('ServiceCertificate read response success');
      return data;
    } else {
      console.error('Unexpected error status code:', status);
      return {
        error: 'An unexpected error occurred in ServiceCertificate read response',
        statusCode: status
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return {
        error: 'An unexpected error occurred in ServiceCertificate read response'
      };
    }
  }
}

export async function addServiceCertificate(
  typeofentity: string,
  state: string,
  acceptableCertificates: string
): Promise<AcceptableCertificateType | ErrorResponseType> {
  try {
    const { data, status } = await axios.post(
      API_URL + `/ServiceAgreement/AddSARecord`,
      {
        typeofentity: typeofentity,
        state: state,
        acceptableCertificates: acceptableCertificates
      },
      { headers: requestHeaders }
    );

    if (status === 200) {
      console.info('ServiceCertificate add response success');
      return data.data;
    } else {
      console.error('Unexpected error status code:', status);
      return { error: 'An unexpected error occurred in ServiceCertificate add response', statusCode: status };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred in ServiceCertificate add response' };
    }
  }
}

export async function editServiceCertificate(
  id: number,
  typeofentity: string,
  state: string,
  acceptableCertificates: string
): Promise<AcceptableCertificateType | ErrorResponseType> {
  try {
    const { data, status } = await axios.put(
      API_URL + `/ServiceAgreement/EditSARecord/${id}`,
      {
        id: id,
        typeofentity: typeofentity,
        state: state,
        acceptableCertificates: acceptableCertificates
      },
      { headers: requestHeaders }
    );

    if (status === 200) {
      console.info('ServiceCertificate edit response success');

      return data.data;
    } else {
      console.error('Unexpected error status code:', status);
      return { error: 'An unexpected error occurred in ServiceCertificate edit response', statusCode: status };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred in ServiceCertificate edit response' };
    }
  }
}

export async function deleteServiceCertificate(id: number): Promise<boolean> {
  try {
    const { status } = await axios.delete(API_URL + `/ServiceAgreement/DeleteSARecord/${id}`, {
      headers: requestHeaders
    });

    if (status === 200) {
      console.info('ServiceCertificate delete response success');
      return true;
    } else {
      console.error('Unexpected error status code:', status);
      return false;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}
