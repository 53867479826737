import axios from 'axios';
import { ChargesType } from '../types/Charges';
import { ErrorResponseType } from '../types/ResponseType';

import { API_URL, BEARER_TOKEN } from '../constants/api';

// * -------------- Headers declaration for Request API Begin---------------------------
interface HeaderType {
  accessControlCredential: string;
  accessControlOrigin: string;
  apiKey: string;
}

const HeaderFields: HeaderType = {
  accessControlCredential: 'Access-Control-Allow-Credentials',
  accessControlOrigin: 'Access-Control-Allow-Origin',
  apiKey: 'XApiKey'
};

const HeaderValues: HeaderType = {
  accessControlCredential: '*',
  accessControlOrigin: '*',
  apiKey: BEARER_TOKEN
};

const requestHeaders = {
  [HeaderFields.accessControlCredential]: HeaderValues.accessControlCredential,
  [HeaderFields.accessControlOrigin]: HeaderValues.accessControlOrigin,
  [HeaderFields.apiKey]: HeaderValues.apiKey
};

export async function getCharges(): Promise<ChargesType[] | ErrorResponseType> {
  try {
    const { data, status } = await axios.get(API_URL + '/Charges', {
      headers: requestHeaders
    });

    if (status === 200) {
      console.info('getCharges read response success');

      return data;
    } else {
      console.error('Unexpected error status code:', status);
      return {
        error: 'An unexpected error occurred in getCharges read response',
        statusCode: status
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return {
        error: 'An unexpected error occurred in getCharges read response'
      };
    }
  }
}

export async function addCharge(
  chargesIncluded: string,
  state: string,
  type: string,
  taxforCharges: string
): Promise<ChargesType | ErrorResponseType> {
  try {
    const { data, status } = await axios.post(
      API_URL + `/Charges/AddCharge`,
      {
        chargesIncluded: chargesIncluded,
        state: state,
        type: type,
        taxforCharges: taxforCharges
      },
      { headers: requestHeaders }
    );

    if (status === 200) {
      console.info('Charge add response success');
      return data.data;
    } else {
      console.error('Unexpected error status code:', status);
      return { error: 'An unexpected error occurred in Charge add response', statusCode: status };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred in Charge add response' };
    }
  }
}

export async function editCharges(
  id: number,
  chargesIncluded: string,
  state: string,
  type: string,
  taxforCharges: string
): Promise<ChargesType | ErrorResponseType> {
  try {
    const { data, status } = await axios.put(
      API_URL + `/Charges/EditChargeRecord/${id}`,
      {
        id: id,
        chargesIncluded: chargesIncluded,
        state: state,
        type: type,
        taxforCharges: taxforCharges
      },
      { headers: requestHeaders }
    );

    if (status === 200) {
      console.info('ChargeRecord edit response success');
      return data.data;
    } else {
      console.error('Unexpected error status code:', status);
      return { error: 'An unexpected error occurred in ChargeRecord edit response', statusCode: status };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred in ChargeRecord edit response' };
    }
  }
}

export async function deleteChargesData(id: number): Promise<boolean> {
  try {
    const { status } = await axios.delete(API_URL + `/Charges/${id}`, {
      headers: requestHeaders
    });

    if (status === 200) {
      console.info('Charges delete response success');
      return true;
    } else {
      console.error('Unexpected error status code:', status);
      return false;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}
