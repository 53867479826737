import { createContext, createRef, Dispatch, ReactNode, RefObject, useEffect, useMemo, useState } from 'react';
import { SetterOrUpdater } from 'recoil';

import { ModalRefProps } from '../../../../components/common/Modal';
import { ToastRefProps } from '../../../../components/common/Toast';
import { getProjectMapping } from '../../../../services/ProjectMappings';
import { ProjectMappingsType } from '../../../../types/Mappings';

type ProjectMappingProviderProps = {
  children: ReactNode;
};

type ProjectMappingList = {
  toastRef: RefObject<ToastRefProps>;
  modalRef: RefObject<ModalRefProps>;
  selectedRow: ProjectMappingsType;
  setSelectedRow: Dispatch<ProjectMappingsType>;
  projectMappingList: ProjectMappingsType[];
  setProjectMappingList: SetterOrUpdater<ProjectMappingsType[]>;
  mappingFullArray: ProjectMappingsType[];
  setMappingFullArray: SetterOrUpdater<ProjectMappingsType[]>;
  gridRefreshRef: number;
  resetGrid: () => Promise<void>;
};

export const ProjectMappingContext = createContext<ProjectMappingList | null>(null);

export function ProjectMappingProvider(props: Readonly<ProjectMappingProviderProps>) {
  const toastRef = createRef<ToastRefProps>();
  const modalRef = createRef<ModalRefProps>();

  const [selectedRow, setSelectedRow] = useState<ProjectMappingsType>({} as ProjectMappingsType);

  const [projectMappingList, setProjectMappingList] = useState<ProjectMappingsType[]>([] as ProjectMappingsType[]);
  const [mappingFullArray, setMappingFullArray] = useState<ProjectMappingsType[]>([] as ProjectMappingsType[]);

  const [gridRefreshRef, setGridRefreshRef] = useState(1);
  const resetGrid = async () => {
    //* Refresh grid every operation done
    setGridRefreshRef(Math.random());
  };

  const fetchProjectMappingList = async () => {
    const projectResponse = await getProjectMapping();

    if (!('error' in projectResponse)) {
      setProjectMappingList(projectResponse);
      setMappingFullArray(projectResponse);
    }
  };

  useEffect(() => {
    fetchProjectMappingList();
  }, []);

  const selectStates = useMemo(
    () => ({
      selectedRow,
      setSelectedRow
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRow]
  );

  return (
    <ProjectMappingContext.Provider
      value={{
        ...{ toastRef, modalRef, gridRefreshRef, resetGrid },
        ...selectStates,
        projectMappingList,
        setProjectMappingList,
        mappingFullArray,
        setMappingFullArray
      }}
    >
      {props.children}
    </ProjectMappingContext.Provider>
  );
}
