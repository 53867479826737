import { AcceptableCertificateType } from '../types/AccetableCertificate';
import { ChargesType } from '../types/Charges';
import { MappingsType } from '../types/Mappings';

export function changeGridType(
  data: Record<string, string | number>[] | ChargesType[] | AcceptableCertificateType[] | MappingsType[]
): {
  result: Record<string, string | number>[] | ChargesType[] | AcceptableCertificateType[] | MappingsType[];
  count: number;
} {
  return {
    result: data,
    count: Object.keys(data).length
  };
}
