import { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import styles from './alertmodal.module.css';

export type ContainerProps = {
  children: React.ReactNode;
};

export type ContainerRefProps = {
  showConfirmBox: () => void;
  hideConfirmBox: () => void;
};

const Container = forwardRef((props: ContainerProps, ref: Ref<ContainerRefProps>) => {
  const { children } = props;
  const [hidden, setHidden] = useState(true);

  useImperativeHandle(
    ref,
    () => ({
      showConfirmBox() {
        setHidden(false);
      },
      hideConfirmBox() {
        setHidden(true);
      }
    }),
    []
  );

  return (
    <div className={styles.container} style={{ display: hidden ? 'none' : 'block' }}>
      <div className={styles.main}>{children}</div>
    </div>
  );
});

export type { ContainerRefProps as AlertModalRefProps };
export default Container;
