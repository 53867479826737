import { ChangeEvent, createRef, useEffect, useMemo, useState } from 'react';

import Modal from '../../../../components/common/Modal';
import { addServiceCertificate, editServiceCertificate } from '../../../../services/ServiceCertificate';
import useServiceCertificateContext from '../../ServiceAgreementCertificate/hooks/useServiceCertificate.hook';
import { OptionsType, SelectRefProps } from '../../../../components/common/Select';
import { getBaseData } from '../../../../services/api';
import { RulesOptions } from '../../../../data/Rules';

function Form() {
  const { toastRef, modalRef, selectedRow, serviceCertificateList, setServiceCertificateList, resetGrid } =
    useServiceCertificateContext();
  const [typeofentity, setTypeofentity] = useState('');
  const [state, setState] = useState('');
  const [acceptableCertificates, setAcceptableCertificates] = useState('');
  const [chargesState, setchargesState] = useState<any[]>([]);
  const stateref = createRef<SelectRefProps>();
  const fetchChargesList = async () => {
    const chargesResponse = await getBaseData();
    if (!('error' in chargesResponse)) {
      RulesOptions['state'] = chargesResponse['state'];
      setchargesState(RulesOptions.state);
    }
  };

  useEffect(() => {
    fetchChargesList();
  }, []);

  const stateOptions: OptionsType[] | undefined = useMemo(
    () =>
      chargesState?.reduce((options: OptionsType[], option) => {
        if (options.findIndex((value) => value.value === option) === -1) {
          options.push({ id: Number(option.id), value: option.value });
        }
        return options;
      }, []),
    [chargesState]
  );

  const handleFilterClick = (value: OptionsType) => {
    setState(value.value);
  };

  useEffect(() => {
    setTypeofentity(selectedRow.typeofentity);
    setState(selectedRow.state);
    setAcceptableCertificates(selectedRow.acceptableCertificates);
  }, [selectedRow]);

  const handleTypeofentity = (e: ChangeEvent<HTMLInputElement>) => {
    setTypeofentity(e.target.value);
  };
  const handleState = (e: ChangeEvent<HTMLInputElement>) => {
    setState(e.target.value);
  };
  const handleAcceptableCertificates = (e: ChangeEvent<HTMLInputElement>) => {
    setAcceptableCertificates(e.target.value);
  };

  const handleSave = () => {
    if (selectedRow.id) {
      handleUpdateServiceCertificates();
    } else {
      handleAddServiceCertificates();
    }
  };

  const handleUpdateServiceCertificates = async () => {
    const existingData = serviceCertificateList.find((item) => item.id === selectedRow.id);

    if (!typeofentity) {
      toastRef.current?.showMessage('Please fill Type of entity', 'error');
      return;
    } else if (
      existingData &&
      selectedRow.typeofentity === typeofentity &&
      selectedRow.state === state &&
      selectedRow.acceptableCertificates === acceptableCertificates
    ) {
      closeModal();
      toastRef.current?.showMessage('No changes', 'success');
      return;
    }

    const editedResponse = await editServiceCertificate(
      Number(selectedRow.id),
      typeofentity,
      state,
      acceptableCertificates
    );

    if (!('error' in editedResponse)) {
      const updatedData = serviceCertificateList.map((value) =>
        value.id === selectedRow.id
          ? {
              ...value,
              typeofentity: editedResponse.typeofentity,
              state: editedResponse.state,
              acceptableCertificates: editedResponse.acceptableCertificates
            }
          : value
      );
      setServiceCertificateList(updatedData);

      closeModal();
      resetGrid();
    }
  };
  const handleAddServiceCertificates = async () => {
    if (!typeofentity) {
      toastRef.current?.showMessage('Please fill Type of entity', 'error');
      return;
    } else if (!state) {
      toastRef.current?.showMessage('Please fill State', 'error');
      return;
    } else if (!acceptableCertificates) {
      toastRef.current?.showMessage('Please fill Acceptable Certificates', 'error');
      return;
    }

    const response = await addServiceCertificate(typeofentity, state, acceptableCertificates);

    if (!('error' in response)) {
      setServiceCertificateList([...serviceCertificateList, ...[response]]);

      closeModal();
      resetGrid();
    }
  };

  const closeModal = () => {
    modalRef.current?.hideModal();
    setTypeofentity('');
    setState('');
    setAcceptableCertificates('');
  };

  return (
    <Modal.Container ref={modalRef} onClose={closeModal}>
      <Modal.Header heading=" Service Agreement Acceptable Certificate" />
      <Modal.Form>
        <Modal.Input
          type="string"
          label="Type of Entity"
          placeHolder={selectedRow.typeofentity}
          value={typeofentity}
          handleOnChange={(e: any) => handleTypeofentity(e)}
        />
        <Modal.Select
          // type="string"
          // label="State"
          // placeHolder={state}
          // value={state}
          // handleOnChange={(e: any) => handleState(e)}
          name="State"
          label="State"
          options={stateOptions || []}
          placeholder={selectedRow.state ? selectedRow.state : 'select state'}
          defaultValue={''}
          type="single"
          disabled={!stateOptions}
          loading={!stateOptions}
          onOptionClick={(value) => handleFilterClick(value)}
          ref={stateref}
        />
        <Modal.TextAreaInput
          type="string"
          label="Acceptable Certificates"
          placeHolder={selectedRow.acceptableCertificates}
          value={acceptableCertificates}
          handleOnChange={(e: any) => handleAcceptableCertificates(e)}
        />
      </Modal.Form>
      <Modal.Footer>
        <Modal.Button name="Save" onClick={handleSave} />
        <Modal.Button name="Close" onClick={() => closeModal()} />
      </Modal.Footer>
    </Modal.Container>
  );
}

export default Form;
