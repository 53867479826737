import { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import styles from './modal.module.css';

export type ContainerProps = {
  children: React.ReactNode;
  onClose?: () => void;
};

export interface ContainerRefProps {
  showModal: () => void;
  hideModal: () => void;
}

const Container = forwardRef((props: ContainerProps, ref: Ref<ContainerRefProps>) => {
  const { children } = props;
  const [show, setShow] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        showModal() {
          setShow(true);
        },
        hideModal() {
          setShow(false);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClose = () => {
    setShow(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div className={styles.model_container}>
      <div className={`${styles.content} ${show ? styles.active : ''}`}>
        <div className={styles.model_main}>
          <div className={styles.model_form}>
            <div className={styles.model_closemain}>
              <button
                type="button"
                className={`${styles.model_closeicon} ${styles.icons_close} `}
                onClick={handleClose}
              ></button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Container;
