import { useContext } from 'react';

import { ChargesContext } from '../hooks/context';

export default function useChargesContext() {
  const context = useContext(ChargesContext);

  if (!context) {
    throw new Error('useChargesContext should be inside ChargesContext');
  }

  return context;
}
