import Form from '../ProjectCertificate/ModalForm';

import { ProjectCertificateProvider } from './hooks/context';
import ProjectCertificateGrid from './ProjectCertificateGrid';
import styles from './layout.module.css';

function Layout() {
  return (
    <ProjectCertificateProvider>
      <div>
        <div className="container-fluid">
          <div className={styles.charges_main}>
            <div className={styles.head_center}>
              <h4 className={styles.charges_gridhead}>Project Acceptable Certificate List</h4>
            </div>
            <Form />
            <div>
              <ProjectCertificateGrid />
            </div>
          </div>
        </div>
      </div>
    </ProjectCertificateProvider>
  );
}

export default Layout;
