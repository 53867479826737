import { useContext } from 'react';

import { QuotedServiceMappingContext } from './context';

export default function useQuotedServiceMappingContext() {
  const context = useContext(QuotedServiceMappingContext);

  if (!context) {
    throw new Error('useQuotedServiceMappingContext should be inside QuotedServiceMappingContext');
  }

  return context;
}
