import Form from '../QuotedMappings/ModalForm';

import styles from './layout.module.css';
import { QuotedServiceMappingProvider } from './hooks/context';
import QuotedServiceMappingGrid from './QuotedServiceMappingGrid';

function Layout() {
  return (
    <QuotedServiceMappingProvider>
      <div className="container-fluid">
        <div className={styles.charges_main}>
          <div className={styles.head_center}>
            <h4 className={styles.charges_gridhead}>Quoted Service Mapping List</h4>
          </div>

          <Form />
          <div>
            <QuotedServiceMappingGrid />
          </div>
        </div>
      </div>
    </QuotedServiceMappingProvider>
  );
}

export default Layout;
