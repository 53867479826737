import axios from 'axios';
import { ErrorResponseType } from '../types/ResponseType';

import { API_URL, BEARER_TOKEN } from '../constants/api';
import { ProjectMappingsType } from '../types/Mappings';

// * -------------- Headers declaration for Request API Begin---------------------------
interface HeaderType {
  accessControlCredential: string;
  accessControlOrigin: string;
  apiKey: string;
}

const HeaderFields: HeaderType = {
  accessControlCredential: 'Access-Control-Allow-Credentials',
  accessControlOrigin: 'Access-Control-Allow-Origin',
  apiKey: 'XApiKey'
};

const HeaderValues: HeaderType = {
  accessControlCredential: '*',
  accessControlOrigin: '*',
  apiKey: BEARER_TOKEN
};

const requestHeaders = {
  [HeaderFields.accessControlCredential]: HeaderValues.accessControlCredential,
  [HeaderFields.accessControlOrigin]: HeaderValues.accessControlOrigin,
  [HeaderFields.apiKey]: HeaderValues.apiKey
};

export async function getProjectMapping(): Promise<ProjectMappingsType[] | ErrorResponseType> {
  try {
    const { data, status } = await axios.get(API_URL + '/BusinessHierarchyProjectMapping/GetBHProjMapping', {
      headers: requestHeaders
    });

    if (status === 200) {
      console.info('ProjectMapping read response success');

      return data;
    } else {
      console.error('Unexpected error status code:', status);
      return {
        error: 'An unexpected error occurred in ProjectMapping read response',
        statusCode: status
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return {
        error: 'An unexpected error occurred in ProjectMapping read response'
      };
    }
  }
}

export async function addProjectMapping(
  Project_Type: string,
  gckCode: string,
  businessHierarchy: string,
  materialNumber: string,
  materialDescription: string,
  productCode: string,
  orderType: string,
  state: string,
  taxabilityGuidance: string,
  materialTaxation: string
): Promise<ProjectMappingsType | ErrorResponseType> {
  try {
    const { data, status } = await axios.post(
      API_URL + `/BusinessHierarchyProjectMapping/AddBHProjMapping`,
      {
        projectType: Project_Type,
        gckCode: gckCode,
        businessHierarchy: businessHierarchy,
        materialNumber: materialNumber,
        materialDescription: materialDescription,
        productCode: productCode,
        orderType: orderType,
        state: state,
        taxabilityGuidance: taxabilityGuidance,
        materialTaxation: materialTaxation
      },
      { headers: requestHeaders }
    );

    if (status === 200) {
      console.info('ProjectMapping add response success');
      return data.data;
    } else {
      console.error('Unexpected error status code:', status);
      return { error: 'An unexpected error occurred in ProjectMapping add response', statusCode: status };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred in ProjectMapping add response' };
    }
  }
}

export async function editProjectMapping(
  id: number,
  Project_Type: string,
  gckCode: string,
  businessHierarchy: string,
  materialNumber: string,
  materialDescription: string,
  productCode: string,
  orderType: string,
  state: string,
  taxabilityGuidance: string,
  materialTaxation: string
): Promise<ProjectMappingsType | ErrorResponseType> {
  try {
    const { data, status } = await axios.put(
      API_URL + `/BusinessHierarchyProjectMapping/EditLineItem/${id}`,
      {
        id: id,
        projectType: Project_Type,
        gckCode: gckCode,
        businessHierarchy: businessHierarchy,
        materialNumber: materialNumber,
        materialDescription: materialDescription,
        productCode: productCode,
        orderType: orderType,
        state: state,
        taxabilityGuidance: taxabilityGuidance,
        materialTaxation: materialTaxation
      },
      { headers: requestHeaders }
    );

    if (status === 200) {
      console.info('ProjectMapping edit response success');

      return data.data;
    } else {
      console.error('Unexpected error status code:', status);
      return { error: 'An unexpected error occurred in ProjectMapping edit response', statusCode: status };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return { error: 'An unexpected error occurred in ProjectMapping edit response' };
    }
  }
}

export async function deleteProjectMapping(id: number): Promise<boolean> {
  try {
    const { status } = await axios.delete(API_URL + `/BusinessHierarchyProjectMapping/${id}`, {
      headers: requestHeaders
    });

    if (status === 200) {
      console.info('ProjectMapping delete response success');
      return true;
    } else {
      console.error('Unexpected error status code:', status);
      return false;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}
