import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import styles from './modal.module.css';
import { SelectPropsType, SelectRefProps, OptionsType } from '../Select';

const Select = forwardRef(function Select(props: Readonly<SelectPropsType>, ref: Ref<SelectRefProps>) {
  const { name, options, defaultValue, label, type, onToggle, onOptionClick, disabled, loading, placeholder } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<OptionsType[]>([]);
  const dropDownRef = useRef<HTMLDivElement>(null);

  const dropdownType = type ?? 'single';
  const filteredOptions = useMemo(
    () => options.filter((option) => option.value.toLowerCase().includes(inputValue.toLowerCase())),
    [inputValue, options]
  );

  useEffect(() => {
    // Function to find option by value
    const findOption = (value: string | number): OptionsType | null => {
      const option = options.find((option) => option.id === value);
      return option || null;
    };

    // Set initial selected values based on defaultValue
    if (Array.isArray(defaultValue)) {
      const initialSelectedValues = defaultValue.reduce((option: OptionsType[], id) => {
        const value = findOption(id);
        value && option.push(value);

        return option;
      }, []);

      setSelectedValue(initialSelectedValues);
    } else {
      const initialSelectedValue = findOption(defaultValue);
      if (initialSelectedValue) {
        setSelectedValue([initialSelectedValue]);
      }
    }
  }, [defaultValue, options]);

  useImperativeHandle(
    ref,
    () => {
      return {
        reset() {
          setSelectedValue([]);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const closeDropdown = async () => {
    setTimeout(() => {
      setIsOpen(false);
      setInputValue('');
    }, 200);
  };

  const openDropdown = async () => {
    setTimeout(() => {
      setIsOpen(true);
    }, 20);
  };

  const toggleDropdown = (): void => {
    if (onToggle && !onToggle()) {
      return;
    }

    if (disabled) {
      return;
    }

    if (!isOpen) {
      openDropdown();
      return;
    }

    // if (dropdownType === 'single') {
    closeDropdown();
    // }
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  //** To close the dropdown option when click outside */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOptionClick = (option: OptionsType) => {
    if (dropdownType === 'single') {
      if (selectedValue.length > 0 && selectedValue.includes(option)) {
        setSelectedValue([]);
      } else {
        setSelectedValue([option]);
      }

      (onOptionClick as (value: OptionsType) => void)(option);

      closeDropdown();
    } else {
      if (option.id === 'all') {
        if (selectedValue.length === options.length) {
          (onOptionClick as (value: OptionsType[]) => void)([]);
          setSelectedValue([]);
        } else {
          (onOptionClick as (value: OptionsType[]) => void)(options);
          setSelectedValue(options);
        }
      } else if (selectedValue.map((item) => item.id).includes(option.id)) {
        const newSelectedValue = selectedValue.filter((item) => item.id !== option.id);
        (onOptionClick as (value: OptionsType[]) => void)(newSelectedValue);
        setSelectedValue(newSelectedValue);
      } else {
        const newSelectedValue = [...selectedValue, option];
        (onOptionClick as (value: OptionsType[]) => void)(newSelectedValue);
        setSelectedValue((prev) => [...prev, option]);
      }
    }
  };

  return (
    <div id={name} className={styles.container} ref={dropDownRef}>
      <p className={styles.label}>{label}</p>
      <div role="dialog" className={styles.select_btn} onClick={toggleDropdown}>
        <i id="arrow_icon" className={`icons-Arrowhead-downbut ${styles.icon}`}></i>
        <span id="selected_value" className={`${styles.input} ${styles.selectanimation}`} onClick={toggleDropdown}>
          <p className={styles.input_value}>
            {loading ? (
              <div className={styles.spinermain}>
                <p className={styles.loadingpara}>Loading...</p>
                <div className="col-sm-1">
                  <div className={styles.spinerloader}></div>
                </div>
              </div>
            ) : (
              (selectedValue.length > 0 && type === 'single' && ` ${selectedValue[0].value}`) ||
              (selectedValue.length > 0 && type === 'multiple' && `${selectedValue.length} Selected`) || (
                <span>{placeholder}</span>
              )
            )}
          </p>
        </span>
      </div>

      {isOpen && (
        <ul className={styles.options_container}>
          <input
            type="text"
            onChange={(e) => handleInputChange(e.target.value)}
            placeholder={'Search...'}
            className={`${styles.searchselect}`}
          />
          {dropdownType === 'multiple' && (
            <li
              className={styles.option}
              key="all"
              onClick={() => handleOptionClick({ id: 'all', value: 'Select All' })}
            >
              <input type="checkbox" onChange={() => () => {}} checked={selectedValue.length === options.length} />
              <span>Select All</span>
            </li>
          )}

          {filteredOptions.map((option) => (
            <li className={styles.option} key={option.id} onClick={() => handleOptionClick(option)}>
              <span className={styles.option_text}>
                <input
                  type="checkbox"
                  onChange={() => () => {}}
                  checked={selectedValue.map((item) => item.value).includes(option.value)}
                />

                {option.value}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});

export default Select;
