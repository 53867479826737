import { ChangeEvent, createRef, useEffect, useMemo, useState } from 'react';

import Modal from '../../../../components/common/Modal';
import { addQuotedServiceMapping, editQuotedServiceMapping } from '../../../../services/QuotedServices';
import useQuotedServiceMappingContext from '../../QuotedMappings/hooks/useQuotedServiceMapping.hook';
import { OptionsType, SelectRefProps } from '../../../../components/common/Select';
import { RulesOptions } from '../../../../data/Rules';
import { getBaseData } from '../../../../services/api';
function Form() {
  const { toastRef, modalRef, selectedRow, quotedServiceMappingList, setQuotedServiceMappingList, resetGrid } =
    useQuotedServiceMappingContext();
  const [project_Type, setProject_Type] = useState('');
  const [gckCode, setGckCode] = useState('');
  const [businessHierarchy, setBusinessHierarchy] = useState('');
  const [materialNumber, setMaterialNumber] = useState('');
  const [materialDescription, setMaterialDescription] = useState('');
  const [productCode, setProductCode] = useState('');
  const [state, setState] = useState('');
  const [taxabilityGuidance, setTaxabilityGuidance] = useState('');
  const [materialTaxation, setMaterialTaxation] = useState('');

  const [chargesState, setchargesState] = useState<any[]>([]);
  const stateref = createRef<SelectRefProps>();
  const fetchChargesList = async () => {
    const chargesResponse = await getBaseData();
    if (!('error' in chargesResponse)) {
      RulesOptions['state'] = chargesResponse['state'];
      setchargesState(RulesOptions.state);
    }
  };

  useEffect(() => {
    fetchChargesList();
  }, []);

  const stateOptions: OptionsType[] | undefined = useMemo(
    () =>
      chargesState?.reduce((options: OptionsType[], option) => {
        if (options.findIndex((value) => value.value === option) === -1) {
          options.push({ id: Number(option.id), value: option.value });
        }
        return options;
      }, []),
    [chargesState]
  );

  const handleFilterClick = (value: OptionsType) => {
    setState(value.value);
  };

  useEffect(() => {
    setProject_Type(selectedRow.projectType);
    setGckCode(selectedRow.gckCode);
    setBusinessHierarchy(selectedRow.businessHierarchy);
    setMaterialNumber(selectedRow.materialNumber);
    setMaterialDescription(selectedRow.materialDescription);
    setProductCode(selectedRow.productCode);
    setState(selectedRow.state);
    setTaxabilityGuidance(selectedRow.taxabilityGuidance);
    setMaterialTaxation(selectedRow.materialTaxation);
  }, [selectedRow]);

  const handleProject_Type = (e: ChangeEvent<HTMLInputElement>) => {
    setProject_Type(e.target.value);
  };
  const handleGckCode = (e: ChangeEvent<HTMLInputElement>) => {
    setGckCode(e.target.value);
  };
  const handleBusinessHierarchy = (e: ChangeEvent<HTMLInputElement>) => {
    setBusinessHierarchy(e.target.value);
  };
  const handleMaterialNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setMaterialNumber(e.target.value);
  };
  const handleMaterialDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setMaterialDescription(e.target.value);
  };
  const handleProductCode = (e: ChangeEvent<HTMLInputElement>) => {
    setProductCode(e.target.value);
  };

  const handleState = (e: ChangeEvent<HTMLInputElement>) => {
    setState(e.target.value);
  };
  const handleTaxabilityGuidance = (e: ChangeEvent<HTMLInputElement>) => {
    setTaxabilityGuidance(e.target.value);
  };
  const handleMaterialTaxation = (e: ChangeEvent<HTMLInputElement>) => {
    setMaterialTaxation(e.target.value);
  };

  const handleSave = () => {
    if (selectedRow.id) {
      handleUpdateQuotedServiceMapping();
    } else {
      handleAddQuotedServiceMapping();
    }
  };

  const handleUpdateQuotedServiceMapping = async () => {
    const existingData = quotedServiceMappingList.find((item) => item.id === selectedRow.id);

    if (!project_Type) {
      toastRef.current?.showMessage('Please fill Project Type', 'error');
      return;
    } else if (
      existingData &&
      selectedRow.projectType === project_Type &&
      selectedRow.gckCode === gckCode &&
      selectedRow.businessHierarchy === businessHierarchy &&
      selectedRow.materialNumber === materialNumber &&
      selectedRow.materialDescription === materialDescription &&
      selectedRow.productCode === productCode &&
      selectedRow.state === state &&
      selectedRow.taxabilityGuidance === taxabilityGuidance &&
      selectedRow.materialTaxation === materialTaxation
    ) {
      closeModal();
      toastRef.current?.showMessage('No changes', 'success');
      return;
    }

    const editedResponse = await editQuotedServiceMapping(
      Number(selectedRow.id),
      project_Type,
      gckCode,
      businessHierarchy,
      materialNumber,
      materialDescription,
      productCode,
      state,
      taxabilityGuidance,
      materialTaxation
    );

    if (!('error' in editedResponse)) {
      const updatedData = quotedServiceMappingList.map((value) =>
        value.id === selectedRow.id
          ? {
              ...value,
              projectType: editedResponse.projectType,
              gckCode: editedResponse.gckCode,
              businessHierarchy: editedResponse.businessHierarchy,
              materialNumber: editedResponse.materialNumber,
              materialDescription: editedResponse.materialDescription,
              productCode: editedResponse.productCode,
              state: editedResponse.state,
              taxabilityGuidance: editedResponse.taxabilityGuidance,
              materialTaxation: editedResponse.materialTaxation
            }
          : value
      );
      setQuotedServiceMappingList(updatedData);

      closeModal();
      resetGrid();
    }
  };
  const handleAddQuotedServiceMapping = async () => {
    if (!project_Type) {
      toastRef.current?.showMessage('Please fill Project Type', 'error');
      return;
    } else if (!gckCode) {
      toastRef.current?.showMessage('Please fill GCK Code', 'error');
      return;
    } else if (!businessHierarchy) {
      toastRef.current?.showMessage('Please fill BusinessHierarchy', 'error');
      return;
    } else if (!materialNumber) {
      toastRef.current?.showMessage('Please fill Material Number', 'error');
      return;
    } else if (!materialDescription) {
      toastRef.current?.showMessage('Please fill Material Description', 'error');
      return;
    } else if (!productCode) {
      toastRef.current?.showMessage('Please fill Product Code', 'error');
      return;
    } else if (!state) {
      toastRef.current?.showMessage('Please fill State', 'error');
      return;
    } else if (!taxabilityGuidance) {
      toastRef.current?.showMessage('Please fill Taxability Guidance', 'error');
      return;
    } else if (!materialTaxation) {
      toastRef.current?.showMessage('Please fill Material Taxation', 'error');
      return;
    }

    const response = await addQuotedServiceMapping(
      project_Type,
      gckCode,
      businessHierarchy,
      materialNumber,
      materialDescription,
      productCode,
      state,
      taxabilityGuidance,
      materialTaxation
    );

    if (!('error' in response)) {
      setQuotedServiceMappingList([...quotedServiceMappingList, ...[response]]);

      closeModal();
      resetGrid();
    }
  };

  const closeModal = () => {
    modalRef.current?.hideModal();
    setProject_Type('');
    setGckCode('');
    setBusinessHierarchy('');
    setMaterialNumber('');
    setMaterialDescription('');
    setProductCode('');
    setState('');
    setTaxabilityGuidance('');
    setMaterialTaxation('');
  };

  return (
    <Modal.Container ref={modalRef} onClose={closeModal}>
      <Modal.Header heading=" Quoted Service Mappings" />
      <Modal.Form>
        <div className="row">
          <div className="col-md-6">
            <Modal.Input
              type="string"
              label="Project Type"
              placeHolder={selectedRow.projectType}
              value={project_Type}
              handleOnChange={(e: any) => handleProject_Type(e)}
            />
            <Modal.Input
              type="string"
              label="GCK Code"
              placeHolder={selectedRow.gckCode}
              value={gckCode}
              handleOnChange={(e: any) => handleGckCode(e)}
            />
            <Modal.Input
              type="string"
              label="Business Hierarchy"
              placeHolder={selectedRow.businessHierarchy}
              value={businessHierarchy}
              handleOnChange={(e: any) => handleBusinessHierarchy(e)}
            />
            <Modal.Input
              type="string"
              label="Material Number"
              placeHolder={selectedRow.materialNumber}
              value={materialNumber}
              handleOnChange={(e: any) => handleMaterialNumber(e)}
            />
          </div>
          <div className="col-md-6">
            <Modal.Input
              type="string"
              label="Material Description"
              placeHolder={selectedRow.materialDescription}
              value={materialDescription}
              handleOnChange={(e: any) => handleMaterialDescription(e)}
            />
            <Modal.Input
              type="string"
              label="Product code"
              placeHolder={selectedRow.productCode}
              value={productCode}
              handleOnChange={(e: any) => handleProductCode(e)}
            />
            <Modal.Select
              // type="string"
              // label="State"
              // placeHolder={state}
              // value={state}
              // handleOnChange={(e: any) => handleState(e)}
              name="State"
              label="State"
              options={stateOptions || []}
              placeholder={selectedRow.state ? selectedRow.state : 'select state'}
              defaultValue={''}
              type="single"
              disabled={!stateOptions}
              loading={!stateOptions}
              onOptionClick={(value) => handleFilterClick(value)}
              ref={stateref}
            />

            <Modal.Input
              type="string"
              label="Material Taxation"
              placeHolder={selectedRow.materialTaxation}
              value={materialTaxation}
              handleOnChange={(e: any) => handleMaterialTaxation(e)}
            />
          </div>
          <div className="col-md-12">
            <Modal.TextAreaInput
              type="string"
              label="Taxability Guidance"
              placeHolder={selectedRow.taxabilityGuidance}
              value={taxabilityGuidance}
              handleOnChange={(e: any) => handleTaxabilityGuidance(e)}
            />
          </div>
        </div>
      </Modal.Form>
      <Modal.Footer>
        <Modal.Button name="Save" onClick={handleSave} />
        <Modal.Button name="Close" onClick={() => closeModal()} />
      </Modal.Footer>
    </Modal.Container>
  );
}

export default Form;
