import { PDFDownloadLink } from '@react-pdf/renderer';
import { createRef, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { SERVICETYPE } from '../constants/servicetype';
import { SelectedRulesContext } from '../context/SelectedRulesContext';
import { RulesConfig, RulesId, RulesOptions, RulesQuestions } from '../data/Rules';
import {
  getAcceptableCertificate,
  getBaseData,
  getBusinessHierarchyCertificate,
  getCharges,
  getCity,
  getServiceAgreementWorkScope,
  getServiceTax,
  getTax,
  getWorkScope,
  getZip
} from '../services/api';
import '../styles/Questions.css';
import { ResponseTypes } from '../types/ResponseType';
import { RuleFieldsIDType, RuleOptionType } from '../types/RulesType';
import PDFfile from './PDFfile';
import Dropdown from './common/Dropdown';
import InputField from './common/InputField';
import LoaderComp from './common/Loader';
import Preloader from './common/Preloader';
import Result from './common/Result';
import ResultGrid from './common/ResultGrid';
import StepProgress, { StepProgressType } from './common/StepProgress';
import Toast, { ToastRefProps } from './common/Toast';
interface QuestionPropsType {
  dataFetched: boolean;
  setDataFetched: Dispatch<SetStateAction<boolean>>;
}

export let iconToActive: Dispatch<SetStateAction<number>>;

function Questions(props: QuestionPropsType) {
  const { dataFetched, setDataFetched } = props;

  const {
    state,
    setState,
    city,
    setCity,
    zip,
    setZip,
    address,
    setAddress,
    estimateType,
    setEstimateType,
    performedFor,
    setPerformedFor,
    work,
    setWork,
    contractType,
    setContractType,
    contractorType,
    setContractorType,
    soldtoCustomer,
    setSoldtoCustomer,
    customerType,
    setCustomerType,
    excemptionCertificate,
    setExcemptionCertificate,
    bHierarchyServAgreement,
    setBHierarchyServAgreement,
    bHierarchyProj,
    setBHierarchyProj,
    bHierarchyQS,
    setBHierarchyQS,
    serviceType,
    setServiceType,
    servicesQS,
    setServicesQS,
    chargeSA,
    setChargeSA,
    chargeProj,
    setChargeProj,
    scope,
    setScope,
    servAgreementScope,
    setServAgreementScope,
    acceptableCertificate,
    setAcceptableCertificate,
    businessHierarchyCertificate,
    setBusinessHierarchyCertificate,
    chargeResponse,
    setChargeResponse,
    tax,
    setTax,
    serviceResponse,
    setServiceResponse
  } = useContext(SelectedRulesContext);

  const [loading, setLoading] = useState(false);
  const [isPreloader, setIsPreloader] = useState(false);
  const toastRef = createRef<ToastRefProps>();

  const [activeIcon, setActiveIcon] = useState<number>(1); // * Show active icon
  const [iconsToShow, setIconsToShow] = useState<Array<StepProgressType>>([]); // * Items contains StepProgressType to give input

  const initialIcons: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const [iconsListToShow, setIconsListToShow] = useState<Array<number>>(initialIcons);

  const [cityData, setCityData] = useState<RuleOptionType[]>([]);
  const [zipData, setZipData] = useState<RuleOptionType[]>([]);
  const [scopeData, setScopeData] = useState<RuleOptionType[]>([]);
  const [servAgreementScopeData, setServAgreementScopeData] = useState<RuleOptionType[]>([]);

  const [submitButtonClick, setSubmitButtonClick] = useState(true);

  const ShowAvalaraButton = () => {
    return (
      <button
        className="button_click"
        onClick={() => window.open('https://app.certcapture.com/logins/login', '_blank')}
      >
        Avalara link to upload certificates
      </button>
    );
  };

  const ShowAddressButton = () => {
    return (
      <button
        className="button_click"
        onClick={() => window.open('https://tools.usps.com/go/ZipLookupActioninput.action', '_blank')}
      >
        Verify the address from the USPS link
      </button>
    );
  };

  const ShowWarning = () => {
    return (
      <div className="col-md-6">
        <div className="line-2">
          <h3 className="lineup-head">Warning</h3>
          <div className="content-body">
            <i className="fa-sharp fa-solid fa-lightbulb line-icon"></i>
            <p className="lineUp-2">Please obtain exemption certificate before the project is awarded</p>
          </div>
        </div>
      </div>
    );
  };

  const ShowNextButton = () => {
    return (
      <button className="button_click" onClick={handleNextClick}>
        Next
      </button>
    );
  };

  const ShowSubmitButton = () => {
    // setSubmitButtonClick(true);
    return (
      <button className="button_click" onClick={handleSubmitClick}>
        Submit
      </button>
    );
  };

  const ShowResetButton = () => {
    return (
      <button className="button_click" onClick={handleResetClick}>
        Reset
      </button>
    );
  };

  const getData = async () => {
    await getBaseData().then((data) => {
      if ('error' in data) {
        return false;
      }
      Object.keys(data).forEach((ruleKey) => {
        RulesOptions[ruleKey as keyof typeof data] = data[ruleKey as keyof typeof data];
      });
    });

    return true;
  };

  const getItems = async () => {
    const newItems: StepProgressType[] = [];

    Object.keys(RulesId).forEach((ruleKey) => {
      const itemToAdd: StepProgressType = {
        id: RulesId[ruleKey as keyof RuleFieldsIDType],
        iconNumber: RulesConfig[ruleKey as keyof RuleFieldsIDType].iconNumber,
        nextIconNumber: RulesConfig[ruleKey as keyof RuleFieldsIDType].nextIconNumber,
        isCompleted: RulesConfig[ruleKey as keyof RuleFieldsIDType].isCompleted
      };

      if (newItems.filter((value) => value.id === itemToAdd.id).length < 1) {
        newItems.push(itemToAdd);
      }
    });
    setIconsToShow(newItems);
  };

  const fetchData = async () => {
    const fetchSucceeded = await getData();

    if (fetchSucceeded) {
      await getItems();

      setTimeout(() => {
        setDataFetched(true);
      }, 500);
    }
  };

  const fetchCity = async () => {
    if (!state) {
      console.error('No city');
    }
    setLoading(true);
    const cityData = await getCity(state);
    if ('error' in cityData) {
      setLoading(false);
      return false;
    }

    RulesOptions['city'] = cityData['City Name'];
    setCityData(RulesOptions['city']);
    setLoading(false);
  };

  const fetchZip = async () => {
    if (!state) {
      console.error('No city');
    }

    const zipData = await getZip(state, city);
    if ('error' in zipData) {
      return false;
    }

    RulesOptions['zip'] = zipData['Zip'];
    setZipData(RulesOptions['zip']);
  };

  const fetchScopeOfWork = async () => {
    if (!bHierarchyProj) {
      console.error('No scope of work');
    }

    const scopeData = await getWorkScope(bHierarchyProj);
    if ('error' in scopeData) {
      return false;
    }

    RulesOptions['scopeOfWork'] = scopeData['Scope'];
    setScopeData(RulesOptions['scopeOfWork']);
  };

  const fetchServAgreementScopeOfWork = async () => {
    if (!bHierarchyServAgreement) {
      console.error('No scope of work');
    }
    const scopeData = await getServiceAgreementWorkScope(bHierarchyServAgreement);
    if ('error' in scopeData) {
      return false;
    }
    RulesOptions['scopeOfWork'] = scopeData['Scope'];
    setServAgreementScopeData(RulesOptions['scopeOfWork']);
  };

  const getChargeSAValues = () => {
    let chargeValues = [];
    for (let i = 0; i < RulesOptions.chargeSA.length; i++) {
      chargeValues.push(RulesOptions.chargeSA[i].value);
    }
    return chargeValues;
  };

  const getChargeProjValues = () => {
    let chargeValues = [];
    for (let i = 0; i < RulesOptions.chargeProj.length; i++) {
      chargeValues.push(RulesOptions.chargeProj[i].value);
    }
    return chargeValues;
  };
  const fetchChargesSA = async () => {
    const charges: Array<string> = await getChargeSAValues();
    const chargesSA: any = await getCharges(state, estimateType, charges.toString());
    setChargeResponse(chargesSA);
  };
  const fetchChargesProj = async () => {
    const charges: Array<string> = await getChargeProjValues();
    const chargesProj: any = await getCharges(state, estimateType, charges.toString());
    setChargeResponse(chargesProj);
  };

  const fetchChargesQS = async () => {
    const charges: Array<string> = await getChargeProjValues();
    const chargesQS: any = await getCharges(state, estimateType, charges.toString());
    setChargeResponse(chargesQS);
  };

  const fetchTaxBeforeAddress = async () => {
    // setLoading(true);
    const res = await getTax(city, state, zip, address ? address : '0');
    if ('Tax Rate' in res) {
      setLoading(false);
      setTax(res);
    } else if ('error' in res) {
      setLoading(false);
      console.log(res);
      toastRef.current?.showMessage('Error in fetching the data.', 'error');
    }
  };

  const fetchTax = async () => {
    return await getTax(city, state, zip, address ? address : '0');
  };

  const getServicesProjValues = () => {
    let servicesValues = [];
    for (let i = 0; i < RulesOptions.serviceType.length; i++) {
      servicesValues.push(RulesOptions.serviceType[i].value);
    }
    return servicesValues;
  };

  const getServicesQSValues = () => {
    let servicesValues = [];
    for (let i = 0; i < RulesOptions.servicesQS.length; i++) {
      servicesValues.push(RulesOptions.servicesQS[i].value);
    }
    return servicesValues;
  };

  const fetchServiceTax = async () => {
    const services: Array<string> = await getServicesProjValues();
    const servicesProj: any = await getServiceTax(state, services.toString(), estimateType);
    setServiceResponse(servicesProj);
  };

  const fetchServiceQSTax = async () => {
    const services: Array<string> = await getServicesQSValues();
    const servicesQS: any = await getServiceTax(state, services.toString(), estimateType);
    setServiceResponse(servicesQS);
  };

  const fetchAcceptableCertificate = async () => {
    const estimateTypeMappings = {
      [SERVICETYPE.serviceAgreement]: 'ServiceAgreement',
      [SERVICETYPE.project]: 'Project',
      [SERVICETYPE.quotedService]: 'Project'
    };

    return await getAcceptableCertificate(estimateTypeMappings[estimateType], state, customerType);
  };

  const fetchBusinessHierarchyCertificate = async () => {
    const bHierarchymapping = {
      [SERVICETYPE.serviceAgreement]: 'BusinessHierarchyServiceAgreementMapping',
      [SERVICETYPE.project]: 'BusinessHierarchyProjectMapping',
      [SERVICETYPE.quotedService]: 'BusinessHierarchyQSMapping'
    };

    const bHierarchyValuemapping = {
      [SERVICETYPE.serviceAgreement]: servAgreementScope,
      [SERVICETYPE.project]: scope,
      [SERVICETYPE.quotedService]: bHierarchyQS
    };

    return await getBusinessHierarchyCertificate(
      bHierarchymapping[estimateType],
      state,
      bHierarchyValuemapping[estimateType]
    );
  };

  const handleNextClick = () => {
    const objIndex = iconsToShow.findIndex((obj) => obj.id === iconsListToShow[activeIcon - 1]);

    iconsToShow[objIndex].isCompleted = true;
    let nextIconNumber = iconsToShow[objIndex].nextIconNumber || activeIcon;

    setActiveIcon(nextIconNumber);
  };

  const handleSubmitClick = () => {
    //fetchServiceTax();
    setSubmitButtonClick(false);
  };

  const handleResetClick = () => {
    window.location.reload();
    setSubmitButtonClick(true);
  };

  useEffect(() => {
    if (!state) {
      return;
    }
    fetchCity();
    setCity('');
    setZip('');
    setTax({ 'Tax Rate': '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (!city) {
      return;
    }

    fetchZip();
    setZip('');
    setTax({ 'Tax Rate': '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  useEffect(() => {
    if (state && city && zip) {
      fetchTaxBeforeAddress();
    }
  }, [state, city, zip, address]);

  useEffect(() => {
    if (!bHierarchyProj) {
      return;
    }
    fetchScopeOfWork();
    setScope('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bHierarchyProj]);

  useEffect(() => {
    if (!bHierarchyServAgreement) {
      return;
    }
    fetchServAgreementScopeOfWork();
    setServAgreementScope('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bHierarchyServAgreement]);

  useEffect(() => {
    if (!dataFetched) {
      fetchData();
      iconToActive = setActiveIcon;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetched]);

  useEffect(() => {
    if (estimateType === SERVICETYPE.serviceAgreement) {
      setIconsListToShow([...initialIcons, 13]);
      iconsToShow.forEach((element) => {
        if (element.id === 12 || element.id === 13) {
          element.isCompleted = false;
        }
      });
      fetchChargesSA();
      setBHierarchyProj('');
      setServiceType([]);
      setChargeSA([]);
    } else if (
      estimateType === SERVICETYPE.project &&
      (state === 'Texas' || state === 'Iowa' || state === 'North Carolina' || state === 'Kansas')
    ) {
      setIconsListToShow([...initialIcons, 12, 13]);

      iconsToShow.forEach((element) => {
        if (element.id === 13 || element.id === 16) {
          element.isCompleted = false;
        }
      });
      fetchChargesProj();
      fetchServiceTax();
      setBHierarchyServAgreement('');
      setServiceType([]);
      setChargeProj([]);
    } else if (
      estimateType === SERVICETYPE.project &&
      state !== 'Texas' &&
      state !== 'Iowa' &&
      state !== 'North Carolina' &&
      state !== 'Kansas'
    ) {
      setIconsListToShow([...initialIcons, 12]);

      iconsToShow.forEach((element) => {
        if (element.id === 13 || element.id === 16) {
          element.isCompleted = false;
        }
      });
      fetchChargesProj();
      fetchServiceTax();
      setBHierarchyServAgreement('');
      setServiceType([]);
      setChargeProj([]);
    } else if (estimateType === SERVICETYPE.quotedService) {
      setIconsListToShow([...initialIcons, 11]);

      iconsToShow.forEach((element) => {
        if (element.id === 12 || element.id === 15) {
          element.isCompleted = false;
        }
      });
      fetchChargesQS();
      fetchServiceQSTax();
      setBHierarchyServAgreement('');
      setServicesQS([]);
      setChargeProj([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateType]);

  const serviceTypeSiQuote = () => {
    return (
      <div>
        <ul style={{ listStyleType: 'none' }}>
          {serviceType.map((value, index) => (
            <li key={index}>
              <input type="checkbox" id={`checkbox_${index}`} />
              <label htmlFor={`checkbox_${index}`}>{value}</label>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  let charges = ['Material - Taxable', chargeResponse.Charge];
  const finalCharges = charges.join('|');
  const chargeArray = finalCharges.split('|');
  const chargeSiQuote = () => {
    return (
      <div>
        <ul style={{ listStyleType: 'none' }}>
          {chargeArray.map((value, index) => (
            <li key={index} className="align-space">
              <input checked={value.includes('Taxable')} type="checkbox" id={`checkbox_${index}`} />
              <label className="label-align-item" htmlFor={`checkbox_${index}`}>
                {value.split(' - ')[0]}
              </label>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const serviceArray = serviceResponse['Service Type'].split('|');

  const removeMRRA = () => {
    if (state === 'Arizona') {
      return scopeData;
    } else {
      return scopeData.filter((data) => data.value !== 'AZ MRRA – Existing Structure for Arizona State');
    }
  };

  const getServices = () => {
    return (
      <div>
        <ul style={{ listStyleType: 'none' }}>
          {serviceArray.map((value, index) => (
            <li key={index} className="align-space">
              <input checked={value.includes('Taxable')} type="checkbox" id={`checkbox_${index}`} />
              <label className="label-align-item" htmlFor={`checkbox_${index}`}>
                {value.split(' - ')[0]}
              </label>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  if (dataFetched) {
    return (
      <div className="col-md-8">
        <div className="content">
          <Toast ref={toastRef} />
          {loading && <LoaderComp />}
          <StepProgress items={iconsToShow} setState={setActiveIcon} iconsListToShow={iconsListToShow} />
          {activeIcon === 1 && (
            <Dropdown
              name="state"
              question={RulesQuestions.state}
              options={RulesOptions.state}
              handleOptionClick={handleNextClick}
              state={state}
              setState={setState}
              grid={true}
            />
          )}
          {activeIcon === 2 && (
            <Dropdown
              name="city"
              question={RulesQuestions.city}
              options={cityData}
              handleOptionClick={handleNextClick}
              state={city}
              setState={setCity}
              grid={true}
              mode="autocomplete"
            />
          )}
          {activeIcon === 3 && (
            <Dropdown
              name="zip"
              question={RulesQuestions.zip}
              options={zipData}
              handleOptionClick={handleNextClick}
              state={zip}
              setState={setZip}
              grid={true}
              mode="dropdown"
            />
          )}
          {activeIcon === 4 && (
            <>
              <div className="taxRate">
                <p className="taxrate-text">Based on the State, City and ZipCode, the Tax is : </p>
                {loading ? (
                  <div className="spinermain">
                    <p className="loadingpara">Loading...</p>
                  </div>
                ) : (
                  <p className="taxrate-text"> {tax['Tax Rate']}</p>
                )}
              </div>

              <h6 className="streetClass">{RulesQuestions.address}</h6>
              <span className="streetSpan">Please enter the correct Street address to get the exact Tax Rate</span>
              <InputField
                className={'form-control input-box-design'}
                type="text"
                placeHolder={''}
                value={address}
                handleOnChange={(e) => setAddress(e.target.value)}
              />
              {tax && (
                <span className="streetSpan">
                  If the Street address is incorrect/invalid/blank, the Tax rate will be based on State, City and
                  ZipCode
                </span>
              )}
              <br />
              <br />
              <ShowAddressButton />
              <button className="button_click" onClick={handleNextClick}>
                Next
              </button>
            </>
          )}
          {activeIcon === 5 && (
            <Dropdown
              name="estimateType"
              question={RulesQuestions.estimateType}
              options={RulesOptions.estimateType}
              handleOptionClick={handleNextClick}
              state={estimateType}
              setState={setEstimateType}
            />
          )}
          {estimateType === SERVICETYPE.project &&
          (state === 'Texas' || state === 'Iowa' || state === 'North Carolina' || state === 'Kansas') ? (
            <>
              {activeIcon === 6 && (
                <Dropdown
                  name="performedFor"
                  question={RulesQuestions.performedFor}
                  options={RulesOptions.performedFor}
                  handleOptionClick={handleNextClick}
                  state={performedFor}
                  setState={setPerformedFor}
                />
              )}
              {activeIcon === 7 && (
                <Dropdown
                  name="projectType"
                  question={RulesQuestions.work}
                  options={RulesOptions.work}
                  handleOptionClick={handleNextClick}
                  state={work}
                  setState={setWork}
                />
              )}
              {activeIcon === 8 && (
                <Dropdown
                  name="contractType"
                  question={RulesQuestions.contractType}
                  options={RulesOptions.contractType}
                  handleOptionClick={handleNextClick}
                  state={contractType}
                  setState={setContractType}
                />
              )}
              {activeIcon === 9 && (
                <Dropdown
                  name="contractorType"
                  question={RulesQuestions.contractorType}
                  options={RulesOptions.contractorType}
                  handleOptionClick={handleNextClick}
                  state={contractorType}
                  setState={setContractorType}
                />
              )}
              {activeIcon === 10 && (
                <Dropdown
                  name="soldtoCustomer"
                  question={RulesQuestions.soldToCustomer}
                  options={RulesOptions.soldToCustomer}
                  handleOptionClick={handleNextClick}
                  state={soldtoCustomer}
                  setState={setSoldtoCustomer}
                />
              )}
              {activeIcon === 11 && (
                <Dropdown
                  name="customerType"
                  question={RulesQuestions.customerType}
                  options={RulesOptions.customerType}
                  handleOptionClick={handleNextClick}
                  state={customerType}
                  setState={setCustomerType}
                />
              )}
              {activeIcon === 12 && (
                <>
                  <Dropdown
                    name="excemptionCertificate"
                    question={RulesQuestions.excemptionCertificate}
                    options={RulesOptions.excemptionCertificate}
                    state={excemptionCertificate}
                    setState={setExcemptionCertificate}
                  />

                  {excemptionCertificate && (
                    <div className="row">
                      {excemptionCertificate === 'No' && <ShowWarning />}
                      <Result
                        result={acceptableCertificate}
                        setResult={setAcceptableCertificate as Dispatch<SetStateAction<ResponseTypes>>}
                        fetchFunctionResponse={fetchAcceptableCertificate()}
                      />
                    </div>
                  )}

                  {acceptableCertificate['Acceptable Certificate'] && (
                    <>
                      <ShowAvalaraButton />
                      <ShowNextButton />
                    </>
                  )}
                </>
              )}
              {activeIcon === 13 && (
                <>
                  <Dropdown
                    name="bHierarchyProj"
                    question={RulesQuestions.bHierarchyProj}
                    options={RulesOptions.bHierarchyProj}
                    state={bHierarchyProj}
                    setState={setBHierarchyProj}
                  />
                  {bHierarchyProj && (
                    <Dropdown name="bHierarchyproj" options={scopeData} state={scope} setState={setScope} />
                  )}
                  {!submitButtonClick && (
                    <div className="row">
                      <Result
                        result={businessHierarchyCertificate}
                        setResult={setBusinessHierarchyCertificate as Dispatch<SetStateAction<ResponseTypes>>}
                        fetchFunctionResponse={fetchBusinessHierarchyCertificate()}
                        removeKey={['Material Taxation']}
                      />
                      {/* <Result
                        result={chargeResponse}
                        setResult={setChargeResponse as Dispatch<SetStateAction<ResponseTypes>>}
                        fetchFunctionResponse={fetchChargesProj()}
                      /> */}
                      <Result
                        result={tax}
                        setResult={setTax as Dispatch<SetStateAction<ResponseTypes>>}
                        fetchFunctionResponse={fetchTax()}
                      />
                      {/* <Result result={{ 'Service Type': serviceType.toString() }} /> */}
                      {/* <Result
                        // result={{ 'Service Type': serviceType.toString() }}
                        result={serviceResponse}
                        setResult={setServiceResponse as Dispatch<SetStateAction<ResponseTypes>>}
                        fetchFunctionResponse={fetchServiceTax()}
                      /> */}

                      <ResultGrid
                        installType={businessHierarchyCertificate['Project Type']}
                        scope={scope}
                        materialTaxation={businessHierarchyCertificate['Material Taxation']}
                        charges={chargeSiQuote()}
                        tax={tax['Tax Rate']}
                        city={city}
                        state={state}
                        zip={zip}
                        estimateType="project"
                        labour={getServices()}
                      />
                    </div>
                  )}
                  {submitButtonClick && <ShowSubmitButton />}
                  {!submitButtonClick && (
                    <>
                      <ShowResetButton />
                      {excemptionCertificate && acceptableCertificate && businessHierarchyCertificate && (
                        <PDFDownloadLink
                          document={
                            <PDFfile
                              state={state}
                              city={city}
                              zip={zip}
                              address={address}
                              estimateType={estimateType}
                              performedFor={performedFor}
                              work={work}
                              contractType={contractType}
                              contractorType={contractorType}
                              soldToCustomer={soldtoCustomer}
                              customerType={customerType}
                              exmptionCertificate={excemptionCertificate}
                              acceptable_Certificates={acceptableCertificate['Acceptable Certificate']}
                              bHierarchyProj={bHierarchyProj}
                              scope={scope}
                              project_Type={businessHierarchyCertificate['Project Type']}
                              gcK_Code={businessHierarchyCertificate['GCK Code']}
                              order_Type={businessHierarchyCertificate['Order Type']}
                              material_Number={businessHierarchyCertificate['Material Number']}
                              taxability_Guidance={businessHierarchyCertificate['Taxability Guidance']}
                              charges={chargeArray}
                              serviceType={serviceArray}
                            />
                          }
                          fileName="Tax"
                        >
                          <button className="button_click">Export to PDF</button>
                        </PDFDownloadLink>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {activeIcon === 6 && (
                <Dropdown
                  name="projectType"
                  question={RulesQuestions.work}
                  options={RulesOptions.work}
                  handleOptionClick={handleNextClick}
                  state={work}
                  setState={setWork}
                />
              )}
              {activeIcon === 7 && (
                <Dropdown
                  name="contractType"
                  question={RulesQuestions.contractType}
                  options={RulesOptions.contractType}
                  handleOptionClick={handleNextClick}
                  state={contractType}
                  setState={setContractType}
                />
              )}
              {activeIcon === 8 && (
                <Dropdown
                  name="contractorType"
                  question={RulesQuestions.contractorType}
                  options={RulesOptions.contractorType}
                  handleOptionClick={handleNextClick}
                  state={contractorType}
                  setState={setContractorType}
                />
              )}
              {activeIcon === 9 && (
                <Dropdown
                  name="soldtoCustomer"
                  question={RulesQuestions.soldToCustomer}
                  options={RulesOptions.soldToCustomer}
                  handleOptionClick={handleNextClick}
                  state={soldtoCustomer}
                  setState={setSoldtoCustomer}
                />
              )}
              {activeIcon === 10 && (
                <Dropdown
                  name="customerType"
                  question={RulesQuestions.customerType}
                  options={RulesOptions.customerType}
                  handleOptionClick={handleNextClick}
                  state={customerType}
                  setState={setCustomerType}
                />
              )}
              {activeIcon === 11 && (
                <>
                  <Dropdown
                    name="excemptionCertificate"
                    question={RulesQuestions.excemptionCertificate}
                    options={RulesOptions.excemptionCertificate}
                    state={excemptionCertificate}
                    setState={setExcemptionCertificate}
                  />

                  {excemptionCertificate && (
                    <div className="row">
                      {excemptionCertificate === 'No' && <ShowWarning />}
                      <Result
                        result={acceptableCertificate}
                        setResult={setAcceptableCertificate as Dispatch<SetStateAction<ResponseTypes>>}
                        fetchFunctionResponse={fetchAcceptableCertificate()}
                      />
                    </div>
                  )}

                  {acceptableCertificate['Acceptable Certificate'] && (
                    <>
                      <ShowAvalaraButton />
                      <ShowNextButton />
                    </>
                  )}
                </>
              )}
            </>
          )}

          {activeIcon === 12 &&
            ((estimateType === SERVICETYPE.serviceAgreement && (
              <>
                <Dropdown
                  name="bHierarchyServAgreement"
                  question={RulesQuestions.bHierarchyServAgreement}
                  options={RulesOptions.bHierarchyServAgreement}
                  state={bHierarchyServAgreement}
                  setState={setBHierarchyServAgreement}
                  //type="multiple"
                />
                {bHierarchyServAgreement && (
                  <Dropdown
                    name="bHierarchyServAgreement"
                    options={servAgreementScopeData}
                    state={servAgreementScope}
                    setState={setServAgreementScope}
                    //type="multiple"
                  />
                )}
                {/* {submitButtonClick && <ShowSubmitButton />}
                {!submitButtonClick && <ShowResetButton />} */}
                {!submitButtonClick && (
                  <div className="row">
                    <Result
                      result={businessHierarchyCertificate}
                      setResult={setBusinessHierarchyCertificate as Dispatch<SetStateAction<ResponseTypes>>}
                      fetchFunctionResponse={fetchBusinessHierarchyCertificate()}
                      removeKey={['Material Taxation', 'Order Type']}
                    />
                    {/* <Result
                      result={chargeResponse}
                      setResult={setChargeResponse as Dispatch<SetStateAction<ResponseTypes>>}
                      fetchFunctionResponse={fetchChargesSA()}
                    /> */}
                    <Result
                      result={tax}
                      setResult={setTax as Dispatch<SetStateAction<ResponseTypes>>}
                      fetchFunctionResponse={fetchTax()}
                    />
                    <ResultGrid
                      installType={businessHierarchyCertificate['Project Type']}
                      scope={servAgreementScope}
                      materialTaxation={businessHierarchyCertificate['Material Taxation']}
                      charges={chargeSiQuote()}
                      tax={tax['Tax Rate']}
                      city={city}
                      state={state}
                      zip={zip}
                      estimateType="service"
                      labour={getServices()}
                    />
                  </div>
                )}
                {submitButtonClick && <ShowSubmitButton />}
                {!submitButtonClick && (
                  <>
                    <ShowResetButton />
                    {excemptionCertificate && acceptableCertificate && businessHierarchyCertificate && (
                      <PDFDownloadLink
                        document={
                          <PDFfile
                            state={state}
                            city={city}
                            zip={zip}
                            address={address}
                            estimateType={estimateType}
                            work={work}
                            contractType={contractType}
                            contractorType={contractorType}
                            soldToCustomer={soldtoCustomer}
                            customerType={customerType}
                            exmptionCertificate={excemptionCertificate}
                            acceptable_Certificates={acceptableCertificate['Acceptable Certificate']}
                            bHierarchyServAgreement={bHierarchyServAgreement}
                            servAgreementScope={servAgreementScope}
                            project_Type={businessHierarchyCertificate['Project Type']}
                            gcK_Code={businessHierarchyCertificate['GCK Code']}
                            material_Number={businessHierarchyCertificate['Material Number']}
                            taxability_Guidance={businessHierarchyCertificate['Taxability Guidance']}
                            charges={chargeArray}
                          />
                        }
                        fileName="Tax"
                      >
                        <button className="button_click">Export to PDF</button>
                      </PDFDownloadLink>
                    )}
                  </>
                )}
              </>
            )) ||
              (estimateType === SERVICETYPE.project &&
                state !== 'Texas' &&
                state !== 'Iowa' &&
                state !== 'North Carolina' &&
                state !== 'Kansas' && (
                  <>
                    <Dropdown
                      name="bHierarchyProj"
                      question={RulesQuestions.bHierarchyProj}
                      options={
                        state === 'Arizona'
                          ? RulesOptions.bHierarchyProj
                          : RulesOptions.bHierarchyProj.filter((data) => data.value !== 'AZ MRRA - Existing Structure')
                      }
                      state={bHierarchyProj}
                      setState={setBHierarchyProj}
                      //type="multiple"
                    />
                    {bHierarchyProj && (
                      <Dropdown
                        name="bHierarchyProj"
                        question={RulesQuestions.bHierarchyProj}
                        options={
                          state === 'Arizona'
                            ? scopeData
                            : scopeData.filter(
                                (data) => data.value !== 'AZ MRRA – Existing Structure for Arizona State'
                              )
                        }
                        state={scope}
                        setState={setScope}
                        //type="multiple"
                      />
                    )}
                    {/* {submitButtonClick && <ShowSubmitButton />}
                {!submitButtonClick && <ShowResetButton />} */}
                    {!submitButtonClick && (
                      <div className="row">
                        <Result
                          result={businessHierarchyCertificate}
                          setResult={setBusinessHierarchyCertificate as Dispatch<SetStateAction<ResponseTypes>>}
                          fetchFunctionResponse={fetchBusinessHierarchyCertificate()}
                          removeKey={['Material Taxation']}
                        />
                        {/* <Result
                      result={chargeResponse}
                      setResult={setChargeResponse as Dispatch<SetStateAction<ResponseTypes>>}
                      fetchFunctionResponse={fetchChargesSA()}
                    /> */}
                        <Result
                          result={tax}
                          setResult={setTax as Dispatch<SetStateAction<ResponseTypes>>}
                          fetchFunctionResponse={fetchTax()}
                        />
                        <ResultGrid
                          installType={businessHierarchyCertificate['Project Type']}
                          scope={scope}
                          materialTaxation={businessHierarchyCertificate['Material Taxation']}
                          charges={chargeSiQuote()}
                          tax={tax['Tax Rate']}
                          city={city}
                          state={state}
                          zip={zip}
                          estimateType="service"
                          labour={getServices()}
                        />
                      </div>
                    )}
                    {submitButtonClick && <ShowSubmitButton />}
                    {!submitButtonClick && (
                      <>
                        <ShowResetButton />
                        {excemptionCertificate && acceptableCertificate && businessHierarchyCertificate && (
                          <PDFDownloadLink
                            document={
                              <PDFfile
                                state={state}
                                city={city}
                                zip={zip}
                                address={address}
                                estimateType={estimateType}
                                work={work}
                                contractType={contractType}
                                contractorType={contractorType}
                                soldToCustomer={soldtoCustomer}
                                customerType={customerType}
                                exmptionCertificate={excemptionCertificate}
                                acceptable_Certificates={acceptableCertificate['Acceptable Certificate']}
                                bHierarchyProj={bHierarchyProj}
                                scope={scope}
                                project_Type={businessHierarchyCertificate['Project Type']}
                                gcK_Code={businessHierarchyCertificate['GCK Code']}
                                order_Type={businessHierarchyCertificate['Order Type']}
                                material_Number={businessHierarchyCertificate['Material Number']}
                                taxability_Guidance={businessHierarchyCertificate['Taxability Guidance']}
                                charges={chargeArray}
                                serviceType={serviceArray}
                              />
                            }
                            fileName="Tax"
                          >
                            <button className="button_click">Export to PDF</button>
                          </PDFDownloadLink>
                        )}
                      </>
                    )}
                  </>
                )) ||
              (estimateType === SERVICETYPE.quotedService && (
                <>
                  <Dropdown
                    name="bHierarchyQuotedService"
                    question={RulesQuestions.bHierarchyQS}
                    options={RulesOptions.bHierarchyQS}
                    state={bHierarchyQS}
                    setState={setBHierarchyQS}
                  />
                  {!submitButtonClick && (
                    <div className="row">
                      <Result
                        result={businessHierarchyCertificate}
                        setResult={setBusinessHierarchyCertificate as Dispatch<SetStateAction<ResponseTypes>>}
                        fetchFunctionResponse={fetchBusinessHierarchyCertificate()}
                        removeKey={['Material Taxation', 'Order Type']}
                      />
                      {/* <Result
                        result={chargeResponse}
                        setResult={setChargeResponse as Dispatch<SetStateAction<ResponseTypes>>}
                        fetchFunctionResponse={fetchChargesQS()}
                      /> */}
                      <Result
                        result={tax}
                        setResult={setTax as Dispatch<SetStateAction<ResponseTypes>>}
                        fetchFunctionResponse={fetchTax()}
                      />
                      {/* <Result result={{ 'Service Type': serviceType.toString() }} /> */}
                      {/* <Result
                        // result={{ 'Service Type': serviceType.toString() }}
                        result={serviceResponse}
                        setResult={setServiceResponse as Dispatch<SetStateAction<ResponseTypes>>}
                        fetchFunctionResponse={fetchServiceQSTax()}
                      /> */}

                      <ResultGrid
                        installType={businessHierarchyCertificate['Project Type']}
                        scope={scope}
                        materialTaxation={businessHierarchyCertificate['Material Taxation']}
                        charges={chargeSiQuote()}
                        tax={tax['Tax Rate']}
                        city={city}
                        state={state}
                        zip={zip}
                        estimateType="project"
                        labour={getServices()}
                      />
                    </div>
                  )}
                  {submitButtonClick && <ShowSubmitButton />}
                  {!submitButtonClick && (
                    <>
                      <ShowResetButton />
                      {excemptionCertificate && acceptableCertificate && businessHierarchyCertificate && (
                        <PDFDownloadLink
                          document={
                            <PDFfile
                              state={state}
                              city={city}
                              zip={zip}
                              address={address}
                              estimateType={estimateType}
                              work={work}
                              contractType={contractType}
                              contractorType={contractorType}
                              soldToCustomer={soldtoCustomer}
                              customerType={customerType}
                              exmptionCertificate={excemptionCertificate}
                              acceptable_Certificates={acceptableCertificate['Acceptable Certificate']}
                              bHierarchyQS={bHierarchyQS}
                              project_Type={businessHierarchyCertificate['Project Type']}
                              gcK_Code={businessHierarchyCertificate['GCK Code']}
                              material_Number={businessHierarchyCertificate['Material Number']}
                              taxability_Guidance={businessHierarchyCertificate['Taxability Guidance']}
                              charges={chargeArray}
                              serviceType={serviceArray}
                            />
                          }
                          fileName="Tax"
                        >
                          <button className="button_click">Export to PDF</button>
                        </PDFDownloadLink>
                      )}
                    </>
                  )}
                </>
              )))}
        </div>
      </div>
    );
  }
  return <Preloader />;
}

export default Questions;
