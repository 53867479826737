// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorpage_container__cm8Hi {
  padding: 0 !important;
  margin: 0 !important;
  -webkit-user-select: none;
          user-select: none;
}

.errorpage_main__mA2JM {
  width: 100%;
  height: 100vh;
  padding-right: 70px;
  background: linear-gradient(
    80deg,
    rgba(0, 100, 110, 1) 0%,
    rgba(0 ,175 ,142, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.errorpage_main__mA2JM h6 {
  font-size: 17vw;
  color: #fff;
  font-family: 'font_two';
  background: -webkit-linear-gradient(#fff, #00646e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.errorpage_main__mA2JM small {
  font-size: 3vw;
  color: #fff;
  font-family: 'SiemensSans_Prof_Bold';
  margin: 0.5%;
}

.errorpage_main__mA2JM p {
  font-size: 1.2vw;
  color: #f3f3f0;
  margin: 0.5%;
  font-family: 'font_three';
  letter-spacing: 1px;
}

@media only screen and (max-width: 800px) {
  .errorpage_main__mA2JM {
    padding-right: 0px;
  }
  .errorpage_main__mA2JM h6 {
    font-size: 150px;
  }
  .errorpage_main__mA2JM small {
    font-size: 30px;
  }
  .errorpage_main__mA2JM p {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Error/errorpage.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB;;;;GAIC;EACD,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,uBAAuB;EACvB,kDAAkD;EAClD,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA;EACE,cAAc;EACd,WAAW;EACX,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".container {\r\n  padding: 0 !important;\r\n  margin: 0 !important;\r\n  user-select: none;\r\n}\r\n\r\n.main {\r\n  width: 100%;\r\n  height: 100vh;\r\n  padding-right: 70px;\r\n  background: linear-gradient(\r\n    80deg,\r\n    rgba(0, 100, 110, 1) 0%,\r\n    rgba(0 ,175 ,142, 1) 100%\r\n  );\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n}\r\n\r\n.main h6 {\r\n  font-size: 17vw;\r\n  color: #fff;\r\n  font-family: 'font_two';\r\n  background: -webkit-linear-gradient(#fff, #00646e);\r\n  -webkit-background-clip: text;\r\n  -webkit-text-fill-color: transparent;\r\n}\r\n\r\n.main small {\r\n  font-size: 3vw;\r\n  color: #fff;\r\n  font-family: 'SiemensSans_Prof_Bold';\r\n  margin: 0.5%;\r\n}\r\n\r\n.main p {\r\n  font-size: 1.2vw;\r\n  color: #f3f3f0;\r\n  margin: 0.5%;\r\n  font-family: 'font_three';\r\n  letter-spacing: 1px;\r\n}\r\n\r\n@media only screen and (max-width: 800px) {\r\n  .main {\r\n    padding-right: 0px;\r\n  }\r\n  .main h6 {\r\n    font-size: 150px;\r\n  }\r\n  .main small {\r\n    font-size: 30px;\r\n  }\r\n  .main p {\r\n    font-size: 12px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `errorpage_container__cm8Hi`,
	"main": `errorpage_main__mA2JM`
};
export default ___CSS_LOADER_EXPORT___;
