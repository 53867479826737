import Form from '../Charges/ModalForm';
import ChargesGrid from './ChargesGrid';

import { ChargesProvider } from './hooks/context';
import styles from './index.module.css';

function Layout() {
  return (
    <ChargesProvider>
      <div className="container-fluid">
        <div className={styles.charges_main}>
          <div className={styles.head_center}>
            <h4 className={styles.charges_gridhead}>Charges List</h4>
          </div>
          <Form />
          <div>
            <ChargesGrid />
          </div>
        </div>
      </div>
    </ChargesProvider>
  );
}

export default Layout;
