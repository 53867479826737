import { useContext } from 'react';

import { ProjectMappingContext } from './context';

export default function useProjectMappingContext() {
  const context = useContext(ProjectMappingContext);

  if (!context) {
    throw new Error('useProjectMappingContext should be inside ProjectMappingContext');
  }

  return context;
}
