// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --input-text-color: #000028;
  --input-background-color: #fff;
  --input-text-color-focus: #000;
  --input-background-color-focus: #e5e5e9;
  --input-placeholder-color: #000;
  --input-border-color: #e5e5e9;
}
.textarea_input_box__J8mzd {
  border-radius: 6px !important;
  background: var(--input-background-color);
  font-size: 1vw !important;
  font-weight: 400;
  letter-spacing: 0.5px !important;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  margin: 0.4vw 0px;
  width: 100% !important;
  padding: calc(-4% + 5%) !important;
}

.textarea_input_box__J8mzd:focus {
  color: var(--input-text-color-focus);
  outline: none;
  border: 1px solid #00af8e;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  background: var(--input-background-color);
}

.textarea_input_box__J8mzd::placeholder {
  color: var(--input-placeholder-color);
  letter-spacing: 0.5px;
}
.textarea_input_labeldesign__lXffd {
  font-size: 0.9vw;
  font-family: 'SiemensSans_Prof_Bold';
  color: rgb(0 0 40);
  letter-spacing: 0.3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Textarea/textarea.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,8BAA8B;EAC9B,8BAA8B;EAC9B,uCAAuC;EACvC,+BAA+B;EAC/B,6BAA6B;AAC/B;AACA;EACE,6BAA6B;EAC7B,yCAAyC;EACzC,yBAAyB;EACzB,gBAAgB;EAChB,gCAAgC;EAChC,qDAAqD;EACrD,iBAAiB;EACjB,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;EACpC,aAAa;EACb,yBAAyB;EACzB,qDAAqD;EACrD,yCAAyC;AAC3C;;AAEA;EACE,qCAAqC;EACrC,qBAAqB;AACvB;AACA;EACE,gBAAgB;EAChB,oCAAoC;EACpC,kBAAkB;EAClB,qBAAqB;AACvB","sourcesContent":[":root {\r\n  --input-text-color: #000028;\r\n  --input-background-color: #fff;\r\n  --input-text-color-focus: #000;\r\n  --input-background-color-focus: #e5e5e9;\r\n  --input-placeholder-color: #000;\r\n  --input-border-color: #e5e5e9;\r\n}\r\n.input_box {\r\n  border-radius: 6px !important;\r\n  background: var(--input-background-color);\r\n  font-size: 1vw !important;\r\n  font-weight: 400;\r\n  letter-spacing: 0.5px !important;\r\n  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;\r\n  margin: 0.4vw 0px;\r\n  width: 100% !important;\r\n  padding: calc(-4% + 5%) !important;\r\n}\r\n\r\n.input_box:focus {\r\n  color: var(--input-text-color-focus);\r\n  outline: none;\r\n  border: 1px solid #00af8e;\r\n  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;\r\n  background: var(--input-background-color);\r\n}\r\n\r\n.input_box::placeholder {\r\n  color: var(--input-placeholder-color);\r\n  letter-spacing: 0.5px;\r\n}\r\n.input_labeldesign {\r\n  font-size: 0.9vw;\r\n  font-family: 'SiemensSans_Prof_Bold';\r\n  color: rgb(0 0 40);\r\n  letter-spacing: 0.3px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_box": `textarea_input_box__J8mzd`,
	"input_labeldesign": `textarea_input_labeldesign__lXffd`
};
export default ___CSS_LOADER_EXPORT___;
