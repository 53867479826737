// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_admin_container__LZQZR{
    width: 100%;
    height: 100vh;
    background: #000028;

}

.main_main_links__1Bzvh{
    width: 90%;
    height: 160px;
    background: linear-gradient(80deg, rgba(0, 85 ,124, 1) 0%, rgba(0 ,255 ,185, 1) 100%);
    padding: 10px 20px;
    color: #fff;
    margin: 20px auto;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
}
.main_main_links__1Bzvh:hover{
    background: linear-gradient(190deg, rgba(0, 85 ,124, 1) 0%, rgba(0 ,255 ,185, 1) 100%);
    /* background: linear-gradient(#00557ce7, #00ffbbea); */
}
.main_sidebar_item__llAxf{
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 17px;
}

.main_sidebar_item__llAxf:hover{
    color: #fff;
}
.main_head_text__euV37{
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminPanel/AdminPage/main.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;;AAEvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,qFAAqF;IACrF,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,sFAAsF;IACtF,uDAAuD;AAC3D;AACA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;AACA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".admin_container{\r\n    width: 100%;\r\n    height: 100vh;\r\n    background: #000028;\r\n\r\n}\r\n\r\n.main_links{\r\n    width: 90%;\r\n    height: 160px;\r\n    background: linear-gradient(80deg, rgba(0, 85 ,124, 1) 0%, rgba(0 ,255 ,185, 1) 100%);\r\n    padding: 10px 20px;\r\n    color: #fff;\r\n    margin: 20px auto;\r\n    border-radius: 12px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    font-size: 16px;\r\n    font-weight: 500;\r\n    letter-spacing: 1px;\r\n}\r\n.main_links:hover{\r\n    background: linear-gradient(190deg, rgba(0, 85 ,124, 1) 0%, rgba(0 ,255 ,185, 1) 100%);\r\n    /* background: linear-gradient(#00557ce7, #00ffbbea); */\r\n}\r\n.sidebar_item{\r\n    color: #fff;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    text-align: center;\r\n    font-size: 17px;\r\n}\r\n\r\n.sidebar_item:hover{\r\n    color: #fff;\r\n}\r\n.head_text{\r\n    font-size: 25px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    color: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin_container": `main_admin_container__LZQZR`,
	"main_links": `main_main_links__1Bzvh`,
	"sidebar_item": `main_sidebar_item__llAxf`,
	"head_text": `main_head_text__euV37`
};
export default ___CSS_LOADER_EXPORT___;
