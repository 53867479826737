import { createContext, createRef, Dispatch, ReactNode, RefObject, useEffect, useMemo, useState } from 'react';
import { SetterOrUpdater } from 'recoil';

import { ModalRefProps } from '../../../../components/common/Modal';
import { ToastRefProps } from '../../../../components/common/Toast';
import { getProjectCertificate } from '../../../../services/ProjectCertificate';
import { AcceptableCertificateType } from '../../../../types/AccetableCertificate';

type ProjectCertificateProviderProps = {
  children: ReactNode;
};

type ProjectCertificateList = {
  toastRef: RefObject<ToastRefProps>;
  modalRef: RefObject<ModalRefProps>;
  selectedRow: AcceptableCertificateType;
  setSelectedRow: Dispatch<AcceptableCertificateType>;
  projectCertificateList: AcceptableCertificateType[];
  setProjectCertificateList: SetterOrUpdater<AcceptableCertificateType[]>;
  certificateFullArray: AcceptableCertificateType[];
  setCertificateFullArray: SetterOrUpdater<AcceptableCertificateType[]>;
  gridRefreshRef: number;
  resetGrid: () => Promise<void>;
};

export const ProjectCertificateContext = createContext<ProjectCertificateList | null>(null);

export function ProjectCertificateProvider(props: Readonly<ProjectCertificateProviderProps>) {
  const toastRef = createRef<ToastRefProps>();
  const modalRef = createRef<ModalRefProps>();

  const [selectedRow, setSelectedRow] = useState<AcceptableCertificateType>({} as AcceptableCertificateType);

  const [projectCertificateList, setProjectCertificateList] = useState<AcceptableCertificateType[]>(
    [] as AcceptableCertificateType[]
  );
  const [certificateFullArray, setCertificateFullArray] = useState<AcceptableCertificateType[]>(
    [] as AcceptableCertificateType[]
  );

  const [gridRefreshRef, setGridRefreshRef] = useState(1);
  const resetGrid = async () => {
    //* Refresh grid every operation done
    setGridRefreshRef(Math.random());
  };

  const fetchProjectCertificateList = async () => {
    const projectResponse = await getProjectCertificate();

    if (!('error' in projectResponse)) {
      setProjectCertificateList(projectResponse);
      setCertificateFullArray(projectResponse);
    }
  };

  useEffect(() => {
    fetchProjectCertificateList();
  }, []);

  const selectStates = useMemo(
    () => ({
      selectedRow,
      setSelectedRow
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRow]
  );

  return (
    <ProjectCertificateContext.Provider
      value={{
        ...{ toastRef, modalRef, gridRefreshRef, resetGrid },
        ...selectStates,
        projectCertificateList,
        setProjectCertificateList,
        certificateFullArray,
        setCertificateFullArray
      }}
    >
      {props.children}
    </ProjectCertificateContext.Provider>
  );
}
