import { ChangeEvent } from 'react';
import Textarea from '../Textarea/Textarea';

interface TextAreaInputProps {
  label: string;
  type: string;
  placeHolder: string;
  value: string;
  handleOnChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextAreaInput: React.FC<TextAreaInputProps> = ({ label, type, placeHolder, value, handleOnChange }) => (
  <Textarea
    value={value}
    type={type}
    placeHolder={placeHolder}
    handleOnChange={(e) => handleOnChange(e)}
    label={label}
  />
);

export default TextAreaInput;
