import { createContext, createRef, Dispatch, ReactNode, RefObject, useEffect, useMemo, useState } from 'react';
import { SetterOrUpdater } from 'recoil';

import { ModalRefProps } from '../../../../components/common/Modal';
import { ToastRefProps } from '../../../../components/common/Toast';
import { getCharges } from '../../../../services/charges';
import { ChargesType } from '../../../../types/Charges';

type TriggerLogProviderProps = {
  children: ReactNode;
};

type chargesList = {
  toastRef: RefObject<ToastRefProps>;
  modalRef: RefObject<ModalRefProps>;
  selectedRow: ChargesType;
  setSelectedRow: Dispatch<ChargesType>;
  chargesList: ChargesType[];
  setChargesList: SetterOrUpdater<ChargesType[]>;
  chargesFullArray: ChargesType[];
  setChargesFullArray: SetterOrUpdater<ChargesType[]>;
  gridRefreshRef: number;
  resetGrid: () => Promise<void>;
};

export const ChargesContext = createContext<chargesList | null>(null);

export function ChargesProvider(props: Readonly<TriggerLogProviderProps>) {
  const toastRef = createRef<ToastRefProps>();
  const modalRef = createRef<ModalRefProps>();

  const [selectedRow, setSelectedRow] = useState<ChargesType>({} as ChargesType);

  const [chargesList, setChargesList] = useState<ChargesType[]>([] as ChargesType[]);
  const [chargesFullArray, setChargesFullArray] = useState<ChargesType[]>([] as ChargesType[]);

  const [gridRefreshRef, setGridRefreshRef] = useState(1);
  const resetGrid = async () => {
    //* Refresh grid every operation done
    setGridRefreshRef(Math.random());
  };

  const fetchChargesList = async () => {
    const chargesResponse = await getCharges();

    if (!('error' in chargesResponse)) {
      setChargesList(chargesResponse);
      setChargesFullArray(chargesResponse);
    }
  };

  useEffect(() => {
    fetchChargesList();
  }, []);

  const selectStates = useMemo(
    () => ({
      selectedRow,
      setSelectedRow
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRow]
  );

  return (
    <ChargesContext.Provider
      value={{
        ...{ toastRef, modalRef, gridRefreshRef, resetGrid },
        ...selectStates,
        chargesList,
        setChargesList,
        chargesFullArray,
        setChargesFullArray
      }}
    >
      {props.children}
    </ChargesContext.Provider>
  );
}
