import { useNavigate } from 'react-router-dom';
import styles from './backbutton.module.css';
function BackButton() {
  const navigate = useNavigate();

  return (
    <button type="button" onClick={() => navigate(-1)} className={`${styles.button_label} ${styles.buttoncolor}`}>
      Back
    </button>
  );
}

export default BackButton;
