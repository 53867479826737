import Form from '../ServiceMappings/ModalForm';

import styles from './layout.module.css';
import { ServiceMappingProvider } from './hooks/context';
import ServiceMappingGrid from './ServiceMappingGrid';

function Layout() {
  return (
    <ServiceMappingProvider>
      <div className="container-fluid">
        <div className={styles.charges_main}>
          <div className={styles.head_center}>
            <h4 className={styles.charges_gridhead}>Service Agreement Mapping List</h4>
          </div>
          <Form />
          <div>
            <ServiceMappingGrid />
          </div>
        </div>
      </div>
    </ServiceMappingProvider>
  );
}

export default Layout;
