


import axios from 'axios';
import UserInfoType from '../types/User';
import { ErrorResponseType } from '../types/ResponseType';
import { API_URL, BEARER_TOKEN } from '../constants/api';

// * -------------- Headers declaration for Request API Begin---------------------------
interface HeaderType {
  accessControlCredential: string;
  accessControlOrigin: string;
  apiKey: string;
}

const HeaderFields: HeaderType = {
  accessControlCredential: 'Access-Control-Allow-Credentials',
  accessControlOrigin: 'Access-Control-Allow-Origin',
  apiKey: 'XApiKey'
};

const HeaderValues: HeaderType = {
  accessControlCredential: '*',
  accessControlOrigin: '*',
  apiKey: BEARER_TOKEN
};

const requestHeaders = {
  [HeaderFields.accessControlCredential]: HeaderValues.accessControlCredential,
  [HeaderFields.accessControlOrigin]: HeaderValues.accessControlOrigin,
  [HeaderFields.apiKey]: HeaderValues.apiKey
};

export async function getUserInfo(): Promise<UserInfoType | ErrorResponseType> {
  try {
    const { data, status } = await axios.get(API_URL +'/User/GetUserAccess', {
      headers: requestHeaders
    });
    if (status === 200) {
      return data as UserInfoType;

    } else {
      console.error('Unexpected error status code:', status);
      return {
        error: 'An unexpected error occurred in getUserDetails read response',
        statusCode: status,
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return { error: error.message, statusCode: error.status };
    } else {
      console.error('unexpected error: ', error);
      return {
        error: 'An unexpected error occurred in getUserDetails read response',
      };
    }
  }
}
